import { getOs, getBrowser, abnormalReportKey } from './base.js';
import { getWindowWidth, getWindowHeight, getUID } from './index.js';
import { jsonp } from 'vue-jsonp';

let ISTEST = false;
if (/testwww|localhost|127\.0\.0\.1/.test(location.hostname)) ISTEST = true;

let GLOBAL = {
  Al: {
    uid: '',
    accid: '',
    softtype: 'TANTAO',
    softname: 'TANTAO',
    softver: '1.3.3',
    positionxy: '',
    btype: '',
    subtype: '',
    pgnum: '1',
    pageType: '', // detail
    newstype: 'yule',
    ishot: '0'
  }
};

GLOBAL.Al.uid = getUID();
GLOBAL.Os = getOs();
GLOBAL.Browser = getBrowser();
GLOBAL.abnormalReportKey = abnormalReportKey();
GLOBAL.Al.brosize = getWindowWidth() + ',' + getWindowHeight();
GLOBAL.Al.resolution = window.screen.width + ',' + window.screen.height;

const Log = {
  resetParam(o) {
    return {
      qid: o.qid || 'null',
      vqid: o.v_qid || 'null',
      uid: o.uid || GLOBAL.Al.uid,
      rcgid: '', // 外接渠道，渠道方自己的用户ID
      globalvuid: '', // 软件用户上传的软件用户id
      loginacid: o.accid || GLOBAL.Al.accid, // 有登录系统的产品必传
      shareacid: '',
      softtype: o.soft_type || GLOBAL.Al.softtype,
      softname: o.soft_name || GLOBAL.Al.softname,
      softver: o._vbb || GLOBAL.Al.softver,
      clientos: o.os || GLOBAL.Os,
      browsertype: GLOBAL.Browser,
      needrec: '', //计算画像
      softuserinfo: '',
      coordinate: o.positionxy || GLOBAL.Al.positionxy,
      firstgrade: o.btype || GLOBAL.Al.btype,
      secondgrade: o.subtype || GLOBAL.Al.subtype,
      thirdgrade: '', // 当前跳转新闻所在页面的三级类别
      forthgrade: '', // 当前跳转新闻所在页面的四级类别 注：区分新闻来源，包括：人工新闻rg、明珠号新闻mzh、爬虫新闻crawler。
      fivegrade: o.progress || '',
      sixgrade: o.readtime || '',
      sevengrade: o.pageType, // 当前跳转新闻所在页面的七级类别 注：上报落地页面类型，相当于pagetype
      eightgrage: GLOBAL.abnormalReportKey, // 当前跳转新闻所在页面的八级类别  注：头条站上报每次打开页面唯一id
      pgnum: o.pgnum || GLOBAL.Al.pgnum,
      idx: o.idx,
      newstype: o.newstype || GLOBAL.Al.newstype,
      newssubtype: '',
      newsthirdtype: '',
      newsforthtype: '',
      ishot: o.ishot || GLOBAL.Al.ishot,
      suptop: '',
      recommendtype: '',
      from: encodeURI(o.from || GLOBAL.Al.from),
      to: encodeURI(o.to || GLOBAL.Al.to),
      fr_url: encodeURI(o.fr_url || GLOBAL.Al.frurl),
      detpgnum: '', // 如果详情页有分页功能，上报详情页新闻总页数，跟用户实际阅读页数没有关系
      brosize: GLOBAL.Al.brosize, // 浏览器尺寸大小
      resolution: GLOBAL.Al.resolution //显示器分辨率
    };
  },
  init(o) {
    let Url = '//unionstatis.dfxwdc.com/flow-report/page_arrive';
    if (ISTEST) Url = '//test-unionstatis.dfxwdc.com/flow-report/page_arrive';
    const data = this.resetParam(o);
    jsonp(Url, {
      callbackQuery: 'jsonpcallback',
      dataType: 'jsonp',
      ...data,
      _: new Date().getTime()
    });
  },
  init404(o) {
    let Url = '//erropage.dfxwdc.com/flow-report/error404';
    if (ISTEST) Url = '//test-unionstatis.dfxwdc.com/flow-report/error404';
    const p = this.resetParam(o);
    let param = [
      p.qid || 'null',
      p.uid || 'null',
      p.softtype || 'null',
      p.softname || 'null',
      p.newstype || 'null',
      p['from'] || 'null',
      p.to || 'null',
      p.firstgrade || 'null',
      p.secondgrade || 'null',
      p.idx || 'null',
      p.ishot || 'null',
      p.softver || 'null',
      p.clientos || 'null',
      p.browsertype || 'null',
      'pc',
      p.coordinate || 'null'
    ];
    Url = `${Url}?msg=${encodeURI(param.join('\t'))}`;
    jsonp(
      Url,
      {
        callbackQuery: 'jsonpcallback',
        _: new Date().getTime()
      },
      6000
    );
  },
  initBaidu() {
    setTimeout(() => {
      // 此处粘贴百度统计复制过来的代码
      var _hmt = _hmt || [];
      window._hmt = _hmt;
      (function () {
        document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
        var hm = document.createElement('script');
        hm.src = 'https://hm.baidu.com/hm.js?3ad0c09acfdf8a619670f808ac847df0';
        hm.id = 'baidu_tj';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(hm, s);
      })();
    }, 0);
  },
  // 外链上报
  exter(updataStr, outUrl = '') {
    if (updataStr) {
      let updataArr = updataStr.split('|');
      let url = `//${ISTEST ? 'test-unionstatis.dfxwdc.com' : 'outsideclick.dfxwdc.com'}/flow-report/outer_chain_click`;

      let paramTempArr = [
        'null',
        GLOBAL.Al.uid,
        GLOBAL.Al.softname,
        GLOBAL.Al.softver,
        GLOBAL.Os,
        GLOBAL.Browser,
        updataArr[0],
        updataArr[1],
        updataArr[2],
        updataArr[3],
        encodeURI(GLOBAL.Al.from) || 'null',
        encodeURI(location.href),
        encodeURI(outUrl),
        GLOBAL.Al.softtype
      ];

      jsonp(url, {
        callbackQuery: 'jsonpcallback',
        msg: paramTempArr.join('\t'),
        _: new Date().getTime()
      });
    }
  }
};

/**
 * 页面新闻active到达日志上报
 * @param {*} p
 * {
 *    accid:'', 登录用户的accid
 *    btype:'',
 *    subtype: '',
 *    pageType: '' ,
 *    idx :'' ,
 *    newstype: '',
 *    from: '',
 *    to: '',
 *    fr_url: '',
 * }
 */
export const activeLog = (p) => {
  Log.init(p);
};
/**
 * 页面404到达日志上报
 * @param {*} p
 */
export const err404Log = (p) => {
  Log.init404(p);
};

export const baiduLog = () => {
  Log.initBaidu();
};

// 外链上报
export const exterLog = (updataStr, outUrl) => {
  Log.exter(updataStr, outUrl);
};
