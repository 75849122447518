<template>
  <div class="talk-main" v-wechat-title="this.titleMeta">
    <div class="nav-list" :class="animateClass" id="xcpnav">
      <div class="nav" ref="nav" id="nav-cnt">
        <div class="nav-scroll" id="nav-scroll">
          <ul class="navlist">
            <li
              v-for="(item, idx) in listInfo"
              :key="idx"
              @click="clickModuleLink(item.id, idx)"
              :class="{ navitem: true, cur: cur == item.id }"
              :data-xcp="item.id"
            >
              <a href="javascript:;"
                ><i class="icon" :class="item.icon"></i>{{ item.name }}</a
              >
            </li>
          </ul>
        </div>
        <div class="pop"></div>
      </div>
      <div class="content" :class="{ limitMaxHeight: limitMaxHeightClass }">
        <div class="floatHome" ref="main-float-home">
          <span class="back-home" @click="backHome"></span>
          <span class="scroll-top" @click="backTop"></span>
        </div>
        <div
          class="module"
          :ref="item.id"
          v-for="(item, idx) in listInfo"
          :key="idx"
        >
          <!-- 事件追溯 news-->
          <template v-if="item.key == 'ttxw'">
            <p class="module-title">{{ item.name }}</p>
            <div
              class="event-line"
              v-for="(em, i) in item.data"
              :key="i"
              @click="goTalkDetail(em)"
            >
              <h3>{{ em.topic }}</h3>
              <p class="form">
                {{ em.source }} &nbsp;&nbsp; {{ em.deal_date }}
              </p>
              <div class="article">
                <p v-for="(m, n) in em.news_desc" :key="n">{{ m }}</p>
                <p class="image " v-if="em.img_list && em.img_list.length >= 1">
                  <span
                    class="img-warp"
                    v-for="(imgsrc, h) in em.img_list"
                    :key="h"
                  >
                    <img :src="imgsrc" />
                  </span>
                </p>
              </div>
              <div class="info">
                <span class="read" v-if="em.readnum !== 'undefined'">{{
                  em.readnum || 0
                }}</span>
                <span class="heart" v-if="em.commentnum !== 'undefined'">{{
                  em.commentnum || 0
                }}</span>
                <!-- <span class="stars">{{ em.comment_totals }}</span> -->
                <span class="seemore" :pdata="em.pdata">查看更多</span>
              </div>
            </div>
          </template>
          <!-- 摘要 abstract-->
          <template v-if="item.key == 'zy'">
            <p class="module-title">{{ item.name }}</p>
            <div
              class="event-line topic-abstract"
              v-for="(em, i) in item.data"
              :key="i"
              :pdata="em.pdata"
            >
              <h3>{{ em.topic }}</h3>
              <!-- <p class="form">{{ em.deal_date }} {{ em.source }}</p> -->
              <div class="article">
                <p v-for="(m, n) in em.news_desc" :key="n">{{ m }}</p>
                <p class="image " v-if="em.img_list && em.img_list.length >= 1">
                  <span
                    class="img-warp"
                    v-for="(imgsrc, h) in em.img_list"
                    :key="h"
                  >
                    <img :src="imgsrc" />
                  </span>
                </p>
              </div>
            </div>
          </template>
          <!-- 时间轴 time-->
          <template v-else-if="item.key == 'sjz'">
            <p class="module-title">{{ item.name }}</p>
            <ol class="time-line">
              <li
                class="line-item"
                :class="{ 'need-cursor': em.url }"
                v-for="(em, i) in item.data"
                :key="i"
                :pdata="em.pdata"
                @click="goTalkDetail(em)"
              >
                <span class="icon"></span>
                <p class="date">{{ em.deal_date }}</p>
                <p class="title">
                  {{ em.topic }}
                </p>
                <p class="dis" v-for="(m, n) in em.news_desc" :key="n">
                  {{ m }}
                </p>
                <p class="img" v-if="em.img_list && em.img_list.length">
                  <img :src="em.img_list[0]" />
                </p>
              </li>
            </ol>
            <div class="triggle-button" v-if="item.needPcik">
              <span
                class="pick-up pick-btn"
                @click="sliderUp(item.id, item)"
                v-show="item.needUpPick"
                >展开<i></i
              ></span>
              <span
                class="pick-down pick-btn"
                @click="sliderDown(item.id, item)"
                v-show="item.needDownPick"
                >收起<i></i
              ></span>
            </div>
          </template>
          <!-- 详情回顾 list-->
          <template v-else-if="item.key == 'xgsp'">
            <p class="module-title">{{ item.name }}</p>
            <ol class="reviewlist">
              <li
                class="reviewitem"
                v-for="(em, i) in item.data"
                :key="i"
                @click="goTalkDetail(em)"
                :pdata="em.pdata"
              >
                <p class="img img-warp">
                  <img :src="em.img_list[0]" />
                </p>
                <div class="cos">
                  <p class="text">
                    {{ em.topic }}
                  </p>
                  <!-- <p class="form">{{ em.source }}<br />{{ em.deal_date }}</p> -->
                  <p class="form">
                    <span class="source">{{ em.source }}</span>
                    <span class="readnum">{{ em.readnum }}</span>
                  </p>
                </div>
              </li>
            </ol>
          </template>
          <!-- 视频 video-->
          <template v-else-if="item.key == 'bfq'">
            <p class="module-title">{{ item.name }}</p>
            <div class="talk-video" :pdata="item.data[0].pdata">
              <div class="v">
                <video-player :videoLink="item.data[0].url"></video-player>
              </div>
              <p class="title">{{ item.data[0].topic }}</p>
            </div>
          </template>
        </div>
        <div class="record">
          tantao.com All Right Reserve 版权有所 沪ICP备14038113号-30
        </div>
      </div>
    </div>
    <!-- 话题推荐栏目 -->
    <div
      class="recommend-topic"
      :class="{ isUp: isUp }"
      v-if="hotTopicData.length"
      v-show="viewHotTopic"
    >
      <h3 class="recommend-switch" @click="pcikDownRecTopic">
        话题推荐
        <span class="el-icon-arrow-right pack-down"></span>
      </h3>
      <div class="bounce-cnt">
        <ol>
          <li
            class="reclist"
            v-for="(item, index) in hotTopicData"
            :key="index"
            @click="goHotTopic(item, index)"
          >
            <p class="img">
              <img :src="item.img169" />
            </p>
            <span class="pop"></span>
            <div class="text">
              <p class="title">#{{ item.zt_name }}#</p>
              <p class="num">{{ item.view_totals }}人参与话题</p>
            </div>
          </li>
        </ol>
        <span
          class="pack-up el-icon-arrow-left"
          @click="packUpRecTopic"
          v-show="isUp"
        ></span>
      </div>
    </div>
    <slot name="Talkdetails"></slot>
  </div>
</template>

<script>
import {
  getOffset,
  addClass,
  removeClass,
  scrollTo,
  getScrollPosition,
  dealDate,
  isIEversion,
  detalMinDate,
  getUrlUk,
  getWindowHeight,
  scrollToDistance,
} from '@/util/index';
import { getArticleReadNum } from '@/api/axios.request';
import { mapGetters } from 'vuex';
import videoPlayer from './video/video.vue';
import { getTopicData, getHotRecTopicData } from '@/api/detail';
import VueCookies from 'vue-cookies';
import { activeLog, baiduLog } from '@/util/log.js';
let FormlY = '';
export default {
  name: 'Main',
  data() {
    return {
      needRequireNum: 0, // 新闻阅读、评论统计需请求的次数
      requireNum: 0, // 获取新闻阅读、评论统计的请求接口次数
      titleMeta: '',
      btype: '', // 话题的btype
      pagetype: 'topic', // 页面类型
      newstype: '', // 话题类别
      talkid: '',
      cur: '',
      animateClass: '',
      limitMaxHeightClass: false,
      basicInfo: {}, // 模块基本信息
      listInfo: {}, //  模块列表数据
      isUp: false, // 是否展开
      hotTopicData: [], // 推荐话题数据
      viewHotTopic: true, // 是否展现推荐话题栏目
    };
  },
  created() {
    this.isie = isIEversion();
    this.timerData = {}; //备份时间轴的数据
    this.mdrefs = []; // 收集模块的refs
    this.talkid = this.$route.params.id;
    this.isAnimation = false; // 滚动动画
    this.btype = `topic_${this.talkid}`;
    this.$store.commit('SET_CURRENTTOPICID', this.talkid);
  },
  mounted() {
    this.initData(); //初始化数据
    this.navinit(); // 初始化导航
    this.initHotTopic(); // 初始化热门话题
  },
  beforeRouteEnter(to, from, next) {
    FormlY = from.fullPath;
    next();
  },
  methods: {
    // 日志上报
    reportLog() {
      baiduLog();
      let pdata = VueCookies.get('TJ_DATA');
      VueCookies.remove('TJ_DATA');
      if (pdata) {
        pdata = pdata.split('|');
      } else {
        pdata = [];
      }

      let vfrom = '';
      if (!this.hasUseRefer && document.referrer) {
        vfrom = document.referrer;
        this.$store.commit('SET_HASUSEERFER', true);
      } else {
        if (!FormlY) vfrom = 'null';
        else vfrom = `${location.protocol}//${location.host}${FormlY}`;
      }

      activeLog({
        accid: this.userInfo ? this.userInfo.id : -1,
        btype: pdata[0] || 'null',
        subtype: pdata[1] || 'null',
        pageType: this.btype,
        idx: pdata[2] || 'null',
        newstype: this.newstype,
        pgnum: pdata[4] || 'null',
        from: vfrom,
        to: window.location.href,
        fr_url: vfrom,
      });
    },
    // 跳转探讨详情
    goTalkDetail(item) {
      const uk = getUrlUk(item.url);
      if (!uk) return;
      if (item.pdata) VueCookies.set('TJ_DATA', item.pdata);
      this.$store.commit('SET_CURRENTTOPICID', this.talkid);
      const $tp = document.getElementById('talk-topic');
      const $top = document.getElementById('talk-topic-cos');
      $tp.style.transition = 'transform 0.5s ease 0';
      $top.style.height = `${$top.clientHeight}px`;
      this.$router.push({ path: `/talk/${this.talkid}/uk/${uk}` });
    },
    // 获取所有模块锚点
    initModuleTop() {
      const arr = this.mdrefs.slice(0);
      return arr.map((item) => {
        const o = getOffset(this.$refs[item][0]);
        return o.top - 120;
      });
    },
    // 滚动定位锚点
    scrollTrueMd(arr, sp) {
      const screenHeight = getWindowHeight();
      let idx;
      let len = arr.length;
      let spa = sp + 60 + screenHeight / 2;
      for (let i = 0; i < len; i++) {
        if (spa > arr[i]) {
          idx = i;
        }
      }
      // 针对最底部做处理
      const $bom = this.$refs[this.mdrefs[len - 1]];
      if (
        $bom &&
        $bom[0] &&
        $bom[0].getBoundingClientRect().bottom < screenHeight
      ) {
        idx = len - 1;
      }
      //针对顶部做处理
      if (sp == 0) {
        idx = 0;
      }
      this.cur = this.mdrefs[idx];
    },
    // 滚动监听
    navListenScroll() {
      const scope = this;
      setTimeout(() => {
        const nav = this.$refs.nav;
        const obj = getOffset(nav);
        let top = obj.top;
        top -= 120;
        const arr = this.initModuleTop();
        scrollcb();
        window.addEventListener(
          'scroll',
          function(e) {
            e.preventDefault();
            scrollcb();
          },
          true
        );
        function scrollcb() {
          const o = getScrollPosition(window);
          let sp = o.y;
          // 滚动悬浮功能
          if (sp >= top) {
            addClass(nav, 'nav-scroll-fixed');
          } else {
            removeClass(nav, 'nav-scroll-fixed');
          }
          // 导航自动切换
          if (!scope.isAnimation) {
            scope.scrollTrueMd(arr, sp);
          }
          // 滚动首次展开推荐话题
          if (scope.isOnceUser && sp > 10) {
            scope.isOnceUser = false;
            scope.isUp = true;
            VueCookies.set('TT_ONCEUSER', '1');
          }
        }
      }, 1500);
    },
    // 点击左侧模块导航，实现动画锚点
    clickModuleLink(type, idx) {
      const scope = this;
      scope.isAnimation = true;
      this.cur = type;
      let md = this.$refs[type][0];
      let top = getOffset(md).top;
      top -= 170;
      if (idx == 0) {
        scrollTo(0, 500, () => {
          setTimeout(() => {
            scope.isAnimation = false;
          }, 1000);
        });
      } else {
        scrollTo(top, 500, () => {
          setTimeout(() => {
            scope.isAnimation = false;
          }, 1000);
        });
      }
    },
    // 处理模块数据
    dealModuleListData(res) {
      const scope = this;
      const { zhuanti, section_list } = res;
      let votelist = { data: [{}], name: '' };
      this.titleMeta = `${zhuanti.zt_name}_探讨`;
      this.newstype = zhuanti.zt_type;
      if (this.$route.name == 'currentTalk') {
        this.reportLog();
      }
      // 分发标题全局数据
      this.$store.commit('SET_TOPICINFO', {
        name: zhuanti.zt_name,
        description: zhuanti.zt_desc,
        view_totals: zhuanti.view_totals,
      });

      // 处理列表数
      const mdk = ['bfq', 'xgsp', 'sjz', 'ttxw', 'zy'];
      let list = [];
      let idx = 0;
      section_list.forEach((item) => {
        if (mdk.indexOf(item.section_key) > -1) {
          // 创建subtype
          let subtype = '';
          if (item.section_key == 'zy') {
            subtype = `abstract_${item.section_index}`;
          } else if (item.section_key == 'sjz') {
            subtype = `time_${item.section_index}`;
          } else if (item.section_key == 'xgsp') {
            subtype = `list_${item.section_index}`;
          } else if (item.section_key == 'bfq') {
            subtype = `video_${item.section_index}`;
            // 测试专用
            // item.news_list[0] = {
            //   comment_totals: 0,
            //   id: '10271',
            //   img_list: [
            //     '//ttpcstatic.dftoutiao.com/ecms/ztimage/20210617/2047x1148_60caaa9674d31.jpg_.webp',
            //   ],
            //   news_date: '2021-06-17 09:51:14',
            //   news_desc: '',
            //   news_type: 'live',
            //   section_id: '1155',
            //   source: '',
            //   topic: '直播 | 铸梦太空——神舟十二号载人飞船出征太空',
            //   update_time: '2021-06-17 10:03:26',
            //   url:
            //     'http://liveshowstatic.baidu.com/v1/liveshowstatic/live_4526967651.m3u8?authorization=bce-auth-v1/3a9861ff96dd472f875791b14cb0b6a1/2021-06-17T03:19:25Z/15768000/host/d8e9f248597c61011a7df1d8f66fc205a63943376f05e684784d125527b6404f',
            //   video_time: '',
            //   view_totals: 0,
            // };
          } else if (item.section_key == 'ttxw') {
            subtype = `news_${item.section_index}`;
          }

          if (item.section_key == 'ttxw' || item.section_key == 'xgsp') {
            ++this.needRequireNum;
            scope.getArticleNum(item.news_list, () => {
              ++this.requireNum;
              // 强制刷新下，列表数据
              if (this.needRequireNum == this.requireNum) {
                this.listInfo.push(this.listInfo.pop());
              }
            });
          }

          idx++;
          item.news_list.forEach((m, idx) => {
            m.news_desc = m.news_desc.split('\n').filter((t) => {
              return t;
            });
            m.pdata = `${scope.btype}|${subtype}|${idx}|0`;
            if (item.section_key == 'sjz') {
              m.deal_date = dealDate(m.news_date);
            } else {
              m.deal_date = detalMinDate(m.news_date);
            }
          });
          const id = `${item.section_key}-${item.section_index}`;
          if (idx == 1) this.cur = id;
          const len = item.news_list.length;
          const moduleItem = {
            name: item.section_name,
            icon: `i${item.icon_id ? item.icon_id : 0}`,
            data: null,
            index: item.section_index,
            key: item.section_key,
            id: id, //唯一标识
            needPcik: false, // 时间轴是否需要展开、收缩
            needUpPick: false, // 需要展开
            needDownPick: false, // 需要收缩
          };

          // 时间轴数据需要特殊处理
          if (item.section_key == 'sjz') {
            scope.timerData[id] = len > 0 ? item.news_list : [{}];
            if (len > 5) {
              moduleItem.data = item.news_list.slice(0, 4);
              moduleItem.needPcik = true;
              moduleItem.needUpPick = true;
              moduleItem.needDownPick = false;
            } else {
              moduleItem.data = item.news_list;
            }
          } else {
            moduleItem.data = len > 0 ? item.news_list : [{}];
          }

          list.push(moduleItem);
          this.mdrefs.push(id);
        }
        if (item.section_key == 'tttp') {
          votelist = {
            data: item.news_list.length > 0 ? item.news_list : [{}],
            name: item.section_name,
          };
        }
      });
      this.listInfo = list;
      const { query } = this.$route;
      if (query.column) {
        for (let i = 0; i < this.listInfo.length; i++) {
          if (this.listInfo[i].name === query.column) {
            this.$nextTick(() => {
              this.clickModuleLink(this.listInfo[i].id, 1);
              let newQuery = JSON.parse(JSON.stringify(this.$route.query));
              delete newQuery.column;
              this.$router.replace({ query: newQuery });
            });
            break;
          }
        }
      }

      // 分发投票全局数据
      if (votelist) {
        this.$store.commit('SET_VOTEINFO', votelist);
      }
    },
    // 动态获取文章阅读数
    getArticleNum(data, cb) {
      let arrUk = [];
      data.forEach((item) => {
        const uk = getUrlUk(item.url);
        item.datauk = uk;
        if (uk) arrUk.push(uk);
      });
      getArticleReadNum({
        uk_list: arrUk,
      })
        .then((res) => {
          if (res.data && res.data.code == 200 && res.data.result) {
            const curread = res.data.result;
            data.forEach((m) => {
              if (m.datauk && typeof curread[m.datauk] !== 'undefined') {
                m.readnum = curread[m.datauk].view;
                m.commentnum = curread[m.datauk].comment;
              }
            });
          }
          cb && cb(data);
        })
        .catch(() => {
          cb && cb(data);
        });
    },
    // 初始化数据
    initData() {
      getTopicData({ uk: this.talkid })
        .then((response) => {
          if (!response) {
            this.titleMeta = '探讨';
            if (FormlY == '/talk/error/404') {
              this.$router.push({ path: '/talk/home' });
            } else {
              this.$router.push({ path: '/talk/error/404' });
            }
            return;
          }
          if (response.status == 404) {
            this.titleMeta = '探讨';
            if (FormlY == '/talk/error/404') {
              this.$router.push({ path: '/talk/home' });
            } else {
              this.$router.push({ path: '/talk/error/404' });
            }
            return;
          }
          if (response.status == 200) {
            this.dealModuleListData(response);
            this.$nextTick(() => {
              this.navinit();
              this.navListenScroll();
            });
          }
        })
        .catch(() => {
          if (FormlY == '/talk/error/404') {
            this.$router.push({ path: '/talk/home' });
          } else {
            this.$router.push({ path: '/talk/error/404' });
          }
        });
    },
    //返回首页
    backHome() {
      VueCookies.set('TJ_DATA', `${this.btype}|gohome|0|0`);
      this.$router.push({
        path: '/talk/home',
      });
      scrollTo(0, 200, () => {});
    },
    // 回到顶部
    backTop() {
      scrollTo(0, 200, () => {});
    },
    // 点击展开
    sliderUp(id, item) {
      const mditem = this.timerData[id];
      if (mditem) {
        const len = item.data.length;
        const ndata = mditem.slice(len, len + 4);
        ndata.forEach((m) => {
          item.data.push(m);
        });
        if (len + 4 >= mditem.length) item.needUpPick = false;
        item.needDownPick = true;
      }
    },
    // 点击收缩
    sliderDown(id, item) {
      const mditem = this.timerData[id];
      const len = mditem.length;
      const $m = this.$refs[id][0];
      let h = 0;
      let caleH = 0;
      const top = getScrollPosition().y;
      if ($m) h = $m.clientHeight;
      if (mditem) {
        item.data.splice(4, len - 1);
        item.needUpPick = true;
        item.needDownPick = false;
        this.$nextTick(() => {
          if ($m && h > $m.clientHeight) {
            caleH = top - h + $m.clientHeight;
            scrollTo(caleH, 300);
          }
        });
      }
    },
    // 左侧nav导航
    navinit() {
      const h = getWindowHeight(); // 视口的高度
      const len = this.listInfo.length; // 导航的数量
      const remainh = h - 80 - (len + 2) * 38; // 其中38为导航的高度
      let navh = 0; // 导航需设置高度
      if (remainh < 160) {
        if (h - 60 < 640) {
          navh = h - 80 - 30;
        } else {
          navh = h - 80 - 160;
        }
      } else {
        navh = (this.listInfo.length + 2) * 38;
      }
      this.$refs['nav'].style.height = `${navh}px`;
    },
    // 检测用户是否当天第一次访问
    checkUserOnce() {
      const onceUser = VueCookies.get('TT_ONCEUSER');
      if (!onceUser) {
        this.isOnceUser = true;
      }
    },
    // 初始化热门推荐话题
    initHotTopic() {
      getHotRecTopicData().then((res) => {
        this.checkUserOnce();
        this.hotTopicData = res;
      });
    },
    // 新窗口打开话题
    goHotTopic(item, index) {
      item.pdata = `${this.btype}|talklist|${index}|0|0`;
      VueCookies.set('TJ_DATA', item.pdata);
      let routeUrl = this.$router.resolve({
        path: `/talk/${item.id}/`,
      });
      window.open(routeUrl.href, '_blank');
    },
    // 展开左侧悬浮推荐话题栏目
    pcikDownRecTopic() {
      this.isUp = true;
    },
    // 收缩左侧悬浮推荐话题栏目
    packUpRecTopic() {
      this.isUp = false;
    },
  },
  computed: {
    ...mapGetters(['isViewDetail', 'hasUseRefer', 'userInfo']),
  },
  watch: {
    isViewDetail(newValue) {
      if (newValue) {
        this.viewHotTopic = false;
        this.animateClass = 'narrow-main';
        this.$refs['main-float-home'].style.display = 'none';
      } else {
        this.animateClass = '';
        this.viewHotTopic = true;
        setTimeout(() => {
          this.$refs['main-float-home'].style.display = 'block';
        }, 1000);
      }
    },
    '$route.path': function(n, o) {
      // 从新闻页来，到话题页
      if (
        (n == `/talk/${this.talkid}/` || n == `/talk/${this.talkid}`) &&
        o.indexOf(`/talk/${this.talkid}/uk`) > -1
      ) {
        // 如果是新闻页跳转新闻页，则需要过滤话题页的上报。
        const { query } = this.$route;
        if (query.column == 'common') return;
        // 回到话题并定位播放器模块
        if (query.column == 'bfq') {
          this.$nextTick(() => {
            this.clickModuleLink('bfq-1', 1);
          });
        }
        this.reportLog();
      }
    },
    cur: function() {
      this.$nextTick(() => {
        const $cnt = document.getElementById('nav-scroll');
        const $li = document.getElementsByClassName('cur')[0];
        const H = $cnt.clientHeight / 2;
        if (!$li || !$cnt) return;
        const r =
          $li.getBoundingClientRect().top - $cnt.getBoundingClientRect().top;
        if (this.isie) {
          $cnt.scrollTop = $cnt.scrollTop + r - H;
        } else {
          scrollToDistance($cnt, r - H, 300);
        }
      });
    },
  },
  components: {
    videoPlayer,
  },
};
</script>

<style lang="scss" scoped>
$hack_ie: '#';

.talk-main {
  width: 940px;
  height: 500px;
  margin-top: 10px;
  text-align: center;
  position: relative;
  .nav {
    width: 165px;
    float: left;
    background-color: #fff;
    // box-sizing: border-box;
    padding-top: 20px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
    padding-left: 15px;
    height: 380px;

    height: calc(100vh - 340px);
    position: relative;
    .nav-scroll {
      overflow-y: scroll;
      height: 100%;
      width: 100%;
    }
    .navlist {
      font-size: 14px;
      color: #212121;
      padding-bottom: 76px;
      .navitem {
        line-height: 38px;
        text-align: left;
        padding-left: 25px;
        max-height: 38px;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        a {
          text-decoration: none;
          color: #212121;
        }
        &.cur {
          color: #1985ff;
          a {
            color: #1985ff;
          }
          i.icon {
            background-position-x: -20px;
          }
        }
        .icon {
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -10px;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-image: url(../../../assets/image/topic-nav-sp.png);
          &.i0 {
            background-position: 0 -420px;
          }
          &.i1 {
            background-position: 0 0;
          }
          &.i2 {
            background-position: 0 -40px;
          }
          &.i3 {
            background-position: 0 -420px;
          }
          &.i4 {
            background-position: 0 -60px;
          }
          &.i5 {
            background-position: 0 -380px;
          }
          &.i6 {
            background-position: 0 -80px;
          }
          &.i7 {
            background-position: 0 -100px;
          }
          &.i8 {
            background-position: 0 -320px;
          }
          &.i9 {
            background-position: 0 -340px;
          }
          &.i10 {
            background-position: 0 -360px;
          }
          &.i11 {
            background-position: 0 -120px;
          }
          &.i12 {
            background-position: 0 -140px;
          }
          &.i13 {
            background-position: 0 -160px;
          }
          &.i14 {
            background-position: 0 -400px;
          }
          &.i15 {
            background-position: 0 -20px;
          }
          &.i16 {
            background-position: 0 -180px;
          }
          &.i17 {
            background-position: 0 -200px;
          }
          &.i18 {
            background-position: 0 -220px;
          }
          &.i19 {
            background-position: 0 -240px;
          }
          &.i20 {
            background-position: 0 -260px;
          }
          &.i21 {
            background-position: 0 -280px;
          }
          &.i22 {
            background-position: 0 -300px;
          }
          &.i23 {
            background-position: 0 -440px;
          }
        }
      }
      .icon1 {
        position: absolute;
        top: 5px;
        left: 0;
        width: 25px;
        height: 25px;
        background: url('../../../assets/image/icon1.png') 0 0 no-repeat;
      }
      .icon2 {
        position: absolute;
        top: 5px;
        left: 0;
        width: 25px;
        height: 25px;
        background: url('../../../assets/image/icon1.png') 0 -22px no-repeat;
      }
    }
    .pop {
      position: absolute;
      bottom: 0;
      left: 0;
      width: calc(100% - 7px);
      *width: calc(100% - 17px);
      width: calc(100% - 17px) \0;
      height: 76px;
      background-image: linear-gradient(
        to top,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.9) 50%,
        rgba(255, 255, 255, 0)
      );
      background-image: -o-linear-gradient(
        to top,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.9) 50%,
        rgba(255, 255, 255, 0)
      );
      background-image: moz-linear-gradient(
        to top,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.9) 50%,
        rgba(255, 255, 255, 0)
      );
    }
  }
  .nav-list {
    transition: all 0.3s ease 0s;
  }
  .nav-scroll-fixed {
    position: fixed;
    top: 60px;
    z-index: 10;
  }
  .content {
    width: 748px;
    min-height: 640px;
    float: right;
    .floatHome {
      position: fixed;
      bottom: 30px;
      width: 40px;
      height: 100px;
      opacity: 0.5;
      z-index: 1000;
      margin-left: 758px;
      cursor: pointer;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
      .back-home {
        display: block;
        width: 40px;
        height: 40px;
        background: url(../../../assets/image/back-top.png) no-repeat 0 0;
        &:hover {
          background-position: -40px 0;
        }
      }
      .scroll-top {
        position: absolute;
        bottom: 0;
        display: block;
        width: 40px;
        height: 40px;
        background: url(../../../assets/image/back-top.png) no-repeat 0 -40px;
        &:hover {
          background-position: -40px -40px;
        }
      }
    }
    &.limitMaxHeight {
      height: 640px;
      overflow: hidden;
    }
    .module {
      width: 100%;
      background-color: #fff;
      padding-bottom: 30px;
      margin-bottom: 15px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
      .module-title {
        line-height: 48px;
        border-bottom: 1px solid #f3f3f3;
        padding-left: 40px;
        position: relative;
        text-align: left;
        &:before {
          content: '';
          position: absolute;
          top: 12px;
          left: 16px;
          width: 24px;
          height: 24px;
          background: url('../../../assets/image/icon1.png') 0 -96px no-repeat;
        }
      }
    }

    .module {
      // 事件追溯
      .event-line {
        text-align: left;
        padding-top: 18px;
        padding-left: 28px;
        padding-right: 28px;
        cursor: pointer;
        &.topic-abstract {
          cursor: auto;
          .article {
            padding-bottom: 10px;
            border-bottom: 1px solid #f3f3f3;
          }
          &:hover {
            h3 {
              color: #212121;
            }
          }
        }
        h3 {
          font-size: 16px;
          color: #212121;
          line-height: 25px;
        }
        &:hover {
          h3 {
            color: #1985ff;
          }
        }
        .form {
          font-size: 12px;
          color: #999999;
          line-height: 25px;
        }
        .article {
          color: #656565;
          font-size: 14px;
          line-height: 30px;
          p {
            text-indent: 2rem;
          }
          .image {
            text-indent: 0;
            overflow: hidden;
            padding: 10px 0;
            span {
              width: 164px;
              height: 100px;
              margin-right: 10px;
              float: left;
              box-sizing: border-box;
              border: 1px solid #e8e8e8;
              &:last-child {
                margin-right: 0;
              }
            }
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .info {
          overflow: hidden;
          padding-bottom: 15px;
          margin-top: 5px;
          border-bottom: 1px solid #f3f3f3;
          .read,
          .heart,
          .stars {
            float: left;
            font-size: 12px;
            line-height: 24px;
            padding-left: 30px;
            position: relative;
            margin-right: 20px;
            color: #99a1a9;

            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 20px;
              height: 20px;
              background-position: 0 0;
              background-image: url(../../../assets/image/icon2.png);
              background-repeat: no-repeat;
            }
          }
          .read {
            &::before {
              top: 2px;
              left: 5px;
              background-position: 0px -40px;
            }
          }
          .heart {
            &::before {
              top: 2px;
              left: 6px;
              background-image: url(../../../assets/image/icon2.png);
              background-position: 0px -19px;
            }
          }
          .stars {
            &::before {
              top: 2px;
              left: 6px;
              background-position: 0 -60px;
            }
          }
          .seemore {
            font-size: 14px;
            float: right;
            color: #99a1a9;
            cursor: pointer;
            line-height: 24px;
            padding-right: 15px;
            position: relative;
            &:after {
              content: '';
              position: absolute;
              top: 7px;
              right: 0;
              width: 10px;
              height: 10px;
              background: url(../../../assets/image/more.png) no-repeat center
                center;
            }
            &:hover {
              color: #1985ff;
              text-decoration: underline;
            }
          }
        }
      }
      // 时间轴
      .time-line {
        text-align: left;
        color: #999999;
        font-size: 14px;
        line-height: 25px;
        padding-left: 36px;
        margin-top: 28px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 15px;
          width: 2px;
          height: 100%;
          background-color: #f3f5f5;
        }
        .line-item {
          position: relative;

          .icon {
            position: absolute;
            top: 6px;
            left: -25px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #017ff9;
            &:after {
              content: '';
              position: absolute;
              top: 1.5px;
              left: 1.5px;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #fff;
            }
          }
          margin-bottom: 30px;
          .title {
            line-height: 28px;
            font-size: 16px;
            color: #292929;
            margin-bottom: 5px;
          }
          .dis {
            line-height: 25px;
            font-size: 14px;
            color: #656565;
            padding-bottom: 15px;
            padding-right: 20px;
          }
          &.need-cursor {
            cursor: pointer;
            &:hover {
              .title {
                color: #1985ff;
              }
            }
          }
          .img {
            img {
              max-width: 95%;
              max-height: 360px;
              border: 1px solid #e8e8e8;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .triggle-button {
        text-align: center;
        color: #212121;
        padding-top: 25px;
        .pick-btn {
          line-height: 38px;
          padding: 10px 55px;
          font-size: 13px;
          border: 1px solid #f4f5f6;
          background-color: #f8f8f8;
          cursor: pointer;
          i {
            background-repeat: no-repeat;
            background-position: center center;
            padding-left: 11px;
            margin-left: 10px;
          }
          &:hover {
            background-color: #fff;
            border-color: #8cc2ff;
          }
        }
        .pick-up {
          margin-right: 20px;
          i {
            background-image: url(../../../assets/image/pick-up.png);
          }
        }
        .pick-down {
          i {
            background-image: url(../../../assets/image/pick-down.png);
          }
        }
      }
      // 详情回顾
      .reviewlist {
        overflow: hidden;
        padding-left: 29px;
        text-align: left;
        padding-bottom: 5px;
        .reviewitem {
          float: left;
          margin-top: 20px;
          margin-right: 20px;
          width: 215px;
          height: 220px;
          box-sizing: border-box;
          background-color: #ffffff;
          transition: transform 0.3s ease;
          cursor: pointer;
          &:hover {
            .img {
              img {
                transform: matrix(1.04, 0, 0, 1.04, 0, 0);
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
              }
            }
            box-shadow: 0 1px 1px 0px rgba(198, 200, 207, 0.22),
              0 1px 1px 1px rgba(152, 153, 160, 0.02);
          }
          .img {
            width: 213px;
            height: 121px;
            border: 1px solid #f4f4f4;
            background: #f4f4f4;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .cos {
            box-sizing: border-box;
            width: 100%;
            height: 100px;
            background-color: #ffffff;
            border-bottom: 1px solid #f4f4f4;
            border-left: 1px solid #f4f4f4;
            border-right: 1px solid #f4f4f4;
          }
          .text {
            font-size: 14px;
            line-height: 24px;
            // font-weight: bold;
            padding: 5px 15px 0 15px;
            height: 48px;

            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          &:hover {
            .text {
              color: #1985ff;
            }
          }
          .form {
            font-size: 12px;
            line-height: 20px;
            color: #999;
            padding: 0 15px;
            padding-top: 12px;
            overflow: hidden;
            .source {
              float: left;
            }
            .readnum {
              float: right;
              padding-left: 22px;
              position: relative;
              &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 20px;
                height: 20px;
                background: url(../../../assets/image/icon2.png) no-repeat 0 -40px;
              }
            }
          }
        }
      }

      // 视频
      .talk-video {
        margin-top: 20px;
        margin-left: 29px;
        .v {
          width: 685px;
          min-height: 250px;
        }
        .title {
          margin-top: 15px;
          font-size: 18px;
          text-align: 22px;
          color: #333333;
          text-align: left;
        }
      }
    }
    .record {
      width: 100%;
      font-size: 12px;
      line-height: 45px;
      color: #888888;
      text-align: center;
      background-color: #f7f8f9;
      height: 45px;
    }
  }
  .recommend-topic {
    width: 60px;
    // overflow: hidden;
    height: 180px;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #f9f9f9;
    box-sizing: border-box;
    padding-left: 60px;
    border: 1px solid #f4f4f4;
    border: 1px solid #f4f4f4;
    box-shadow: 0 2px 5px 2px #e2e2e2;
    z-index: 1000;
    transition: width 0.3s ease 0s;
    .bounce-cnt {
      overflow: hidden;
      width: 100%;
      box-sizing: border-box;
      height: 100%;
    }
    .recommend-switch {
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 100px;
      background-color: #1985ff;
      font-size: 18px;
      color: #fff;
      padding: 40px 0px 40px 10px;
      font-weight: normal;
      border-radius: 0 5px 5px 0;
      writing-mode: vertical-lr;
      margin: 0 auto;
      letter-spacing: 5px;
      cursor: pointer;
      &:hover {
        background-color: #067afc;
      }
    }
    ol {
      overflow: hidden;
      float: left;
      padding: 35px 0 0 12px;
      height: 115px;
    }
    .reclist {
      float: left;
      width: 200px;
      height: 115px;
      margin-left: 18px;
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
      .img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          transition: transform 0.5s ease-in;
        }
      }
      .pop {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.3;
      }
      .text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        text-align: left;
        .title {
          margin: 13px 10px 0 10px;
          line-height: 24px;
          font-size: 14px;
          color: #fff;
          font-weight: bold;
        }
        .num {
          position: absolute;
          bottom: 16px;
          left: 0;
          margin: 20px 0 0 13px;
          font-size: 12px;
          line-height: 24px;
          color: #fff;
        }
      }
      &:hover {
        .text {
          .title {
            text-decoration: underline;
          }
        }
        .img {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
    .pack-down {
      // line-height: 25px;
      // height: 100px;
      line-height: 100px;
      cursor: pointer;
      writing-mode: horizontal-tb;
    }
    .pack-up {
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
      height: 100%;
      font-size: 35px;
      line-height: 180px;
      cursor: pointer;
    }
    &.isUp {
      width: calc(100vw - 367px);
      width: calc(100vw - 376px) \9;
      .pack-down {
        display: none;
      }
      .recommend-switch {
        width: 45px;
        padding-left: 15px;
        cursor: default;
      }
      .bounce-cnt {
        padding-right: 50px;
        background-color: #f9f9f9;
        -webkit-animation-name: bounce;
        animation-name: bounce;
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        animation-duration: 1s;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
      }
    }
  }
}
</style>
