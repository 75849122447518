/**
 * 所有关于表单的方法
 *  validate 自定义校验
 *  mobileCodeCountDown 短信倒计时功能
 */

export const validate = {
  // 校验手机号
  validateMobile(rule, value, callback) {
    if (!value) {
      return callback(new Error('请输入手机号'));
    } else if (!Number.isInteger(Number(value))) {
      callback(new Error('手机号必须为数字值'));
    } else if (!/^1[\d]{10}$/.test(value)) {
      callback(new Error('请输入正确的手机号格式'));
    } else {
      callback();
    }
  },
  // 校验图片验证码
  validateImgCode(rule, value, callback) {
    if (value === '') {
      callback(new Error('请输入验证码'));
    } else {
      callback();
    }
  },
  // 校验短信验证码
  validateMobileCode(rule, value, callback) {
    if (value === '') {
      callback(new Error('请输入短信验证码'));
    } else {
      callback();
    }
  },
  // 校验密码
  validatePassword(rule, value, callback) {
    if (value === '') {
      callback(new Error('请输入密码'));
    } else if (this.ruleFormName === 'passwordRuleForm') {
      // 如果是登录，则不校验以下内容
      callback();
    } else if (!/^(?=.*[a-zA-Z])(?=.*\d)[^]{6,16}$/.test(value)) {
      callback(new Error('密码为6-16位字母和数字组成，同时包含字母和数字'));
    } else {
      if (this[this.ruleFormName].password !== '') {
        this.$refs[this.ruleFormName].validateField('confirmPassword');
      }
      callback();
    }
  },
  // 校验确认密码
  validateConfirmPassword(rule, value, callback) {
    if (value === '') {
      callback(new Error('请再次输入新密码'));
    } else if (value !== this[this.ruleFormName].password) {
      callback(new Error('两次输入密码不一致!'));
    } else {
      callback();
    }
  },
  // 校验同意用户协议
  validateUserAgreement(rule, value, callback) {
    if (!value) {
      callback(new Error('请同意用户协议'));
    } else {
      callback();
    }
  },
  // 校验用户姓名/昵称
  validateNickname(rule, value, callback) {
    if (value === '') {
      callback(new Error('请输入姓名'));
    } else if (value.length > 8) {
      callback(new Error('最多输入8个汉字'));
    } else {
      callback();
    }
  },
  // 校验规则
  rules() {
    this.rules = {
      mobile: [{ validator: this.validateMobile, trigger: 'blur' }],
      userName: [{ validator: this.validateMobile, trigger: 'blur' }],
      imgCode: [{ validator: this.validateImgCode, trigger: 'blur' }],
      mobileCode: [{ validator: this.validateMobileCode, trigger: 'blur' }],
      password: [{ validator: this.validatePassword, trigger: 'blur' }],
      confirmPassword: [{ validator: this.validateConfirmPassword, trigger: 'blur' }],
      userAgreement: [{ validator: this.validateUserAgreement, trigger: 'change' }],
      nickname: [{ validator: this.validateNickname, trigger: 'blur' }],
      gender: [{ required: true, message: '请选择性别', trigger: 'change' }]
    };
  }
};

export const mobileCodeCountDown = {
  // 短信验证码倒计时 数据对象
  createCountDownData() {
    let _this = this;

    if (_this.countDownData) {
      clearInterval(_this.countDownData.countDownTimerID);
    }

    _this.countDownData = {
      countDownTimerID: null, // 计时器对象
      mobileCodeText: '获取验证码', // 文本
      mobileCodeSum: 60, // 计时器总秒数
      disabled: false,
      disabledStyle: {}
    };
  },

  // 短信验证码倒计时
  countDown() {
    let _this = this;

    clearInterval(_this.countDownData.countDownTimerID);

    _this.countDownData = {
      countDownTimerID: null, // 计时器对象
      mobileCodeText: '60 秒后重新获取', // 文本
      mobileCodeSum: 60, // 计时器总秒数
      disabled: true,
      disabledStyle: {
        color: '#8590a6',
        cursor: 'no-drop',
        'border-color': '#8590a6'
      }
    };

    _this.countDownData.countDownTimerID = setInterval(() => {
      _this.countDownData.mobileCodeSum--;

      if (_this.countDownData.mobileCodeSum <= 0) {
        clearInterval(_this.countDownData.countDownTimerID);

        _this.countDownData.mobileCodeText = `获取验证码`;
        _this.countDownData.mobileCodeSum = 60;
        _this.countDownData.disabled = false;
        _this.countDownData.disabledStyle = {};
      } else {
        _this.countDownData.mobileCodeText = `${_this.countDownData.mobileCodeSum} 秒后重新获取`;
      }
    }, 1000);
  }
};
