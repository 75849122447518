<template>
  <section class="login-wrap">
    <!-- 登录模块 -->
    <section class="login-box" v-if="isLogin">
      <el-tabs v-model="loginActiveName" @tab-click="loginTabClick">
        <el-tab-pane label="手机登录" name="mobile">
          <el-form
            :model="mobileRuleForm"
            :rules="rules"
            ref="mobileRuleForm"
            class="mobile-ruleForm"
          >
            <el-form-item prop="mobile">
              <el-input
                v-model.number="mobileRuleForm.mobile"
                placeholder="请输入手机号"
                maxlength="11"
              ></el-input>
            </el-form-item>
            <el-form-item prop="imgCode" v-if="frequently">
              <el-input v-model="mobileRuleForm.imgCode" placeholder="验证码">
                <template slot="append"
                  ><img class="img-code" :src="imgCode.img" alt=""
                /></template>
              </el-input>
              <p class="change-img-code" @click="getImgCode">换一张</p>
            </el-form-item>
            <el-form-item prop="mobileCode">
              <el-input
                v-model="mobileRuleForm.mobileCode"
                placeholder="请输入短信密码"
              >
                <template slot="append">
                  <p
                    class="get-mobile-code"
                    :style="countDownData.disabledStyle"
                    @click="getMobileCode('login')"
                  >
                    {{ countDownData.mobileCodeText }}
                  </p>
                </template>
              </el-input>
            </el-form-item>
            <p class="login-tips">未注册手机验证后自动登录</p>
            <el-form-item>
              <el-button
                type="primary"
                @click="loginSubmitForm('mobileRuleForm')"
                >登录</el-button
              >
            </el-form-item>
            <section class="form-footer">
              <el-form-item prop="userAgreement" class="user-agreement">
                <el-checkbox v-model="mobileRuleForm.userAgreement"
                  >我已阅读<a
                    class="userabout"
                    href="/talk/about?type=user"
                    target="_blank"
                    >《用户协议》</a
                  >及<a
                    class="userabout"
                    href="/talk/about?type=privacy"
                    target="_blank"
                    >《隐私政策》</a
                  ></el-checkbox
                >
              </el-form-item>
              <el-checkbox class="month-login" v-model="monthLogin"
                >30天内免登录</el-checkbox
              >
            </section>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="密码登录" name="password">
          <el-form
            :model="passwordRuleForm"
            :rules="rules"
            ref="passwordRuleForm"
            class="password-ruleForm"
          >
            <el-form-item prop="userName">
              <el-input
                v-model="passwordRuleForm.userName"
                placeholder="请输入账号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="passwordRuleForm.password"
                placeholder="请输入密码"
                show-password
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="imgCode">
              <el-input v-model="passwordRuleForm.imgCode" placeholder="验证码">
                <template slot="append"
                  ><img class="img-code" :src="imgCode.img" alt=""
                /></template>
              </el-input>
              <p class="change-img-code" @click="getImgCode">换一张</p>
            </el-form-item>
            <p class="login-tips">
              <span class="forget-password" @click="isLogin = false"
                >忘记密码？</span
              ><span
                class="register-account"
                @click="loginActiveName = 'mobile'"
                >注册账号</span
              >
            </p>
            <el-form-item>
              <el-button
                type="primary"
                @click="loginSubmitForm('passwordRuleForm')"
                >登录</el-button
              >
            </el-form-item>
            <section class="form-footer">
              <el-form-item prop="userAgreement" class="user-agreement">
                <el-checkbox v-model="passwordRuleForm.userAgreement"
                  >我已阅读<a
                    class="userabout"
                    href="/talk/about?type=user"
                    target="_blank"
                    >《用户协议》</a
                  >及<a
                    class="userabout"
                    href="/talk/about?type=privacy"
                    target="_blank"
                    >《隐私政策》</a
                  ></el-checkbox
                >
              </el-form-item>
              <el-checkbox class="month-login" v-model="monthLogin"
                >30天内免登录</el-checkbox
              >
            </section>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <section class="box-footer">
        <p class="footer-tips">
          *该账号系统已与明珠号打通可直接登录<img
            class="mingzhu-logo"
            src="../../assets/image/mingzhu-logo.png"
            alt=""
          />
        </p>
      </section>
    </section>

    <!-- 找回密码模块 -->
    <section class="find-password" v-else>
      <h3 class="title">忘记密码</h3>
      <el-form
        :model="findPasswordRuleForm"
        :rules="rules"
        ref="findPasswordRuleForm"
        class="find-password-ruleForm"
      >
        <el-form-item prop="mobile">
          <el-input
            v-model="findPasswordRuleForm.mobile"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="imgCode">
          <el-input v-model="findPasswordRuleForm.imgCode" placeholder="验证码">
            <template slot="append"
              ><img class="img-code" :src="imgCode.img" alt=""
            /></template>
          </el-input>
          <p class="change-img-code" @click="getImgCode">换一张</p>
        </el-form-item>
        <el-form-item prop="mobileCode">
          <el-input
            v-model="findPasswordRuleForm.mobileCode"
            placeholder="请输入短信密码"
          >
            <template slot="append">
              <p
                class="get-mobile-code"
                :style="countDownData.disabledStyle"
                @click="getMobileCode('updatepsw')"
              >
                {{ countDownData.mobileCodeText }}
              </p>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="findPasswordRuleForm.password"
            placeholder="请输入密码（6-12为字母和数字组成的）"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword">
          <el-input
            v-model="findPasswordRuleForm.confirmPassword"
            placeholder="再次确认密码"
            show-password
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="findPasswordSubmitForm('findPasswordRuleForm')"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </section>
  </section>
</template>

<script>
import { getMobileCode, forgetPassword } from '@/api/axios.request.js';
import { getFrequently, setFrequently } from '@/util/index.js';
import { getImgCode } from '@/util/methods.js';
import { validate, mobileCodeCountDown } from '@/util/form.js';

export default {
  name: 'login',
  data() {
    return {
      isLogin: true, // 登录模块 还是 找回密码模块
      loginActiveName: 'mobile', // 登录 tab
      ruleFormName: 'mobileRuleForm', // 当前使用的表单名称，用于表单自定义校验时获取相应的数据
      frequently: getFrequently() || false, // 短信登录 频繁请求短信验证码时需要先验证图形验证码
      monthLogin: true, // 30 天免登录
      countDownData: {}, // 短信验证码倒计时
      // 图片验证码
      imgCode: {
        id: '',
        img: '',
      },
      // 短信验证码登录
      mobileRuleForm: {
        mobile: '',
        imgCode: '',
        mobileCode: '',
        userAgreement: true, // 用户协议
      },
      // 账号密码登录
      passwordRuleForm: {
        userName: '',
        password: '',
        imgCode: '',
        userAgreement: true, // 用户协议
      },
      // 找回密码
      findPasswordRuleForm: {
        mobile: '',
        imgCode: '',
        mobileCode: '',
        password: '',
        confirmPassword: '',
      },
    };
  },
  watch: {
    isLogin: {
      handler(curVal) {
        if (curVal) {
          this.ruleFormName = `${this.loginActiveName}RuleForm`;
        } else {
          this.ruleFormName = 'findPasswordRuleForm'; // 找回密码表单

          this.getImgCode();

          this.createCountDownData();
        }
      },
      immediate: true,
    },
    loginActiveName: {
      handler() {
        this.getImgCode();
      },
      immediate: true,
    },
  },
  methods: {
    ...validate,
    ...mobileCodeCountDown,
    getImgCode,
    // tab 点击事件
    loginTabClick(tab) {
      this.ruleFormName = `${tab.name}RuleForm`; // 当前表单名称

      this[this.ruleFormName].userAgreement = true;
    },
    // 获取短信验证码
    getMobileCode(type) {
      let _this = this;

      let validateResult = false;

      let RuleForm = _this.ruleFormName;

      let param = {
        type,
        mobile: _this[RuleForm].mobile,
      };

      if (_this[RuleForm].imgCode) {
        param.capid = _this.imgCode.id;
        param.capvalue = _this[RuleForm].imgCode;
      }

      _this.$refs[RuleForm].validateField('mobile', (result) => {
        validateResult = Boolean(result);
      });

      _this.$refs[RuleForm].validateField('imgCode', (result) => {
        validateResult = Boolean(result);
      });

      if (validateResult || _this.countDownData.mobileCodeSum < 60) {
        return;
      }

      getMobileCode(param).then((response) => {
        const { data } = response;

        if (data.code === 200) {
          _this.msgSuccess('短信验证码发送成功，请注意查收！');

          // 开发、测试环境自动天验证码
          if (
            process.env.NODE_ENV === 'development' ||
            location.hostname === 'testwww.tantao.com'
          ) {
            _this[RuleForm].mobileCode = data.result;
          }

          _this.countDown();
        } else if (data.code === 2002) {
          _this.getImgCode();

          _this.msgError(data.message);
        } else {
          if (data.code === 2005 || data.code === 2006) {
            _this.frequently = true;

            setFrequently();
          }

          _this.$nextTick(() => {
            _this.$refs[RuleForm].validateField('imgCode', () => {});
          });

          _this.msgError(data.message);
        }
      });
    },
    // 登录
    loginSubmitForm(formName) {
      let _this = this;

      let ruleForm = _this[_this.ruleFormName];

      _this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.$store
            .dispatch('login', {
              ...ruleForm,
              ..._this.imgCode,
              monthLogin: _this.monthLogin,
              ruleFormName: _this.ruleFormName,
            })
            .then((response) => {
              if (response.code === 200) {
                this.$store.commit('SET_ACCOUNTDIALOG', false);
              }
            });
        } else {
          return false;
        }
      });
    },
    // 找回密码
    findPasswordSubmitForm(formName) {
      let _this = this;

      _this.$refs[formName].validate((valid) => {
        if (valid) {
          forgetPassword({
            mobile: _this.findPasswordRuleForm.mobile,
            smscode: _this.findPasswordRuleForm.mobileCode,
            new_password: _this.findPasswordRuleForm.password,
          }).then((result) => {
            const { data } = result;

            if (data.code === 200) {
              _this.isLogin = true;
              _this.loginActiveName = 'password';

              _this.msgSuccess('密码修改成功');

              _this.$refs[formName].resetFields();
            } else {
              _this.msgError(data.message);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  created() {
    this.rules();

    this.createCountDownData(); // 初始化短信验证码倒计时 数据对象
  },
};
</script>

<style lang="scss" scoped>
.login-wrap {
  .login-box {
    width: 460px;

    /deep/ .el-tabs {
      .el-tabs__header {
        padding: 30px 60px 0;
      }

      .el-tabs__content {
        padding: 0 60px 30px;
      }
    }

    .login-tips {
      margin-top: -5px;
      margin-bottom: 15px;
      height: 20px;
      text-align: center;
      color: #999999;
      font-size: 14px;
      line-height: 20px;

      span {
        color: #157ffd;
        cursor: pointer;
      }

      .forget-password {
        float: left;
      }

      .register-account {
        float: right;
      }
    }

    /deep/ .form-footer {
      position: relative;
      margin-top: -10px;

      .user-agreement {
        margin-bottom: 0;

        .el-form-item__content {
          height: 24px;
          line-height: 24px;
        }
      }

      .month-login {
        position: absolute;
        top: 2px;
        right: 0;

        .el-checkbox__label {
          color: #8d99ac;
          font-size: 12px;
        }
      }
    }

    /deep/ .el-form-item {
      .el-form-item__content {
        height: 40px;
        font-size: 0;
      }

      .el-input {
        height: 40px;
        font-size: 0;

        .el-input__inner {
          padding: 0;
          height: 40px;
          font-size: 14px;
          border-left: none;
          border-top: none;
          border-right: none;
          border-bottom: 1px solid #ebebeb;
          border-radius: 0;
        }

        .el-input-group__append {
          padding: 0;
          background-color: #fff;
          border-left: none;
          border-top: none;
          border-right: none;
          border-bottom: 1px solid #ebebeb;
          border-radius: 0;

          .img-code {
            height: 39px;
            vertical-align: top;
          }

          .get-mobile-code {
            color: #1985ff;
            font-size: 14px;
            cursor: pointer;
          }
        }
      }

      .change-img-code {
        position: absolute;
        top: 8px;
        right: -48px;
        height: 24px;
        color: #157ffd;
        font-size: 12px;
        line-height: 24px;
        cursor: pointer;
      }

      .el-button {
        width: 100%;
      }

      .el-checkbox {
        line-height: 24px;

        .el-checkbox__label {
          color: #8d99ac;
          font-size: 12px;
        }
      }
    }

    .box-footer {
      height: 55px;
      background-color: #fafafa;

      .footer-tips {
        text-align: center;
        color: #8d99ac;
        font-size: 14px;
        line-height: 55px;

        .mingzhu-logo {
          margin-top: 15px;
          margin-left: 10px;
          width: 25px;
          height: 25px;
          vertical-align: top;
        }
      }
    }

    .userabout {
      color: #1985ff;
      text-decoration: none;
    }
  }

  .find-password {
    width: 400px;

    .title {
      padding: 30px 60px 0;
    }

    .find-password-ruleForm {
      padding: 20px 60px 35px;

      /deep/ .el-form-item {
        &:last-child {
          margin-bottom: 0;
        }

        .el-input {
          .el-input__inner {
            padding: 0;
            height: 40px;
            font-size: 14px;
            border-left: none;
            border-top: none;
            border-right: none;
            border-bottom: 1px solid #ebebeb;
            border-radius: 0;
          }

          .el-input-group__append {
            padding: 0;
            background-color: #fff;
            border-left: none;
            border-top: none;
            border-right: none;
            border-bottom: 1px solid #ebebeb;
            border-radius: 0;

            .img-code {
              height: 39px;
              vertical-align: top;
            }

            .get-mobile-code {
              color: #1985ff;
              font-size: 14px;
              cursor: pointer;
            }
          }
        }

        .change-img-code {
          position: absolute;
          top: 8px;
          right: -48px;
          height: 24px;
          color: #157ffd;
          font-size: 12px;
          line-height: 24px;
          cursor: pointer;
        }

        .el-button {
          width: 100%;
        }
      }
    }
  }
}
</style>
