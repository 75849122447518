export default [
  {
    title: '[中国赞]',
    src: require('@/assets/image/emoji_png/emoji_zhongguozan.png')
  },
  {
    title: '[笑脸]',
    src: require('@/assets/image/emoji_png/emoji_xiaolian.png')
  },
  {
    title: '[doge]',
    src: require('@/assets/image/emoji_png/emoji_doge02.png')
  },
  {
    title: '[喵喵]',
    src: require('@/assets/image/emoji_png/emoji_miaomiao.png')
  },
  {
    title: '[二哈]',
    src: require('@/assets/image/emoji_png/emoji_erha.png')
  },
  {
    title: '[抱抱]',
    src: require('@/assets/image/emoji_png/emoji_baobao.png')
  },
  {
    title: '[摊手]',
    src: require('@/assets/image/emoji_png/emoji_tanshou.png')
  },
  {
    title: '[跪了]',
    src: require('@/assets/image/emoji_png/emoji_gui.png')
  },
  {
    title: '[吃瓜]',
    src: require('@/assets/image/emoji_png/emoji_chigua.png')
  },
  {
    title: '[允悲]',
    src: require('@/assets/image/emoji_png/emoji_kuxiao.png')
  },
  {
    title: '[蜘蛛侠]',
    src: require('@/assets/image/emoji_png/emoji_zhizhuxia01.png')
  },
  {
    title: '[钢铁侠]',
    src: require('@/assets/image/emoji_png/emoji_gangtiexia01.png')
  },
  {
    title: '[坏笑]',
    src: require('@/assets/image/emoji_png/emoji_huaixiao.png')
  },
  {
    title: '[笑cry]',
    src: require('@/assets/image/emoji_png/emoji_xiaoku.png')
  },
  {
    title: '[馋嘴]',
    src: require('@/assets/image/emoji_png/emoji_chanzui.png')
  },
  {
    title: '[拜拜]',
    src: require('@/assets/image/emoji_png/emoji_baibai.png')
  },
  {
    title: '[右哼哼]',
    src: require('@/assets/image/emoji_png/emoji_youhengheng.png')
  },
  {
    title: '[左哼哼]',
    src: require('@/assets/image/emoji_png/emoji_zuohengheng.png')
  },
  {
    title: '[怒骂]',
    src: require('@/assets/image/emoji_png/emoji_zhouma.png')
  },
  {
    title: '[顶]',
    src: require('@/assets/image/emoji_png/emoji_ding.png')
  },
  {
    title: '[微笑]',
    src: require('@/assets/image/emoji_png/emoji_weixioa02.png')
  },
  {
    title: '[偷笑]',
    src: require('@/assets/image/emoji_png/emoji_touxiao.png')
  },
  {
    title: '[舔屏]',
    src: require('@/assets/image/emoji_png/emoji_tianping.png')
  },
  {
    title: '[亲亲]',
    src: require('@/assets/image/emoji_png/emoji_qinqin.png')
  },
  {
    title: '[太开心]',
    src: require('@/assets/image/emoji_png/emoji_taikaixin.png')
  },
  {
    title: '[挤眼]',
    src: require('@/assets/image/emoji_png/emoji_jiyan.png')
  },
  {
    title: '[衰]',
    src: require('@/assets/image/emoji_png/emoji_shuai.png')
  },
  {
    title: '[感冒]',
    src: require('@/assets/image/emoji_png/emoji_kouzhao.png')
  },
  {
    title: '[可怜]',
    src: require('@/assets/image/emoji_png/emoji_kelian.png')
  },
  {
    title: '[汗]',
    src: require('@/assets/image/emoji_png/emoji_han.png')
  },
  {
    title: '[色]',
    src: require('@/assets/image/emoji_png/emoji_huaxin.png')
  },
  {
    title: '[可爱]',
    src: require('@/assets/image/emoji_png/emoji_keai.png')
  },
  {
    title: '[钱]',
    src: require('@/assets/image/emoji_png/emoji_qian.png')
  },
  {
    title: '[思考]',
    src: require('@/assets/image/emoji_png/emoji_sikao.png')
  },
  {
    title: '[生病]',
    src: require('@/assets/image/emoji_png/emoji_shengbing.png')
  },
  {
    title: '[困]',
    src: require('@/assets/image/emoji_png/emoji_kun.png')
  },
  {
    title: '[互粉]',
    src: require('@/assets/image/emoji_png/emoji_hufen02.png')
  },
  {
    title: '[睡]',
    src: require('@/assets/image/emoji_png/emoji_shuijiao.png')
  },
  {
    title: '[并不简单]',
    src: require('@/assets/image/emoji_png/emoji_bingbujiandan.png')
  },
  {
    title: '[害羞]',
    src: require('@/assets/image/emoji_png/emoji_haixiu.png')
  },
  {
    title: '[费解]',
    src: require('@/assets/image/emoji_png/emoji_wenhao.png')
  },
  {
    title: '[挖鼻]',
    src: require('@/assets/image/emoji_png/emoji_wabi.png')
  },
  {
    title: '[悲伤]',
    src: require('@/assets/image/emoji_png/emoji_beishang.png')
  },
  {
    title: '[打脸]',
    src: require('@/assets/image/emoji_png/emoji_dalian.png')
  },
  {
    title: '[抓狂]',
    src: require('@/assets/image/emoji_png/emoji_zhuakuang.png')
  },
  {
    title: '[哈哈]',
    src: require('@/assets/image/emoji_png/emoji_haha.png')
  },
  {
    title: '[傻眼]',
    src: require('@/assets/image/emoji_png/emoji_shayan.png')
  },
  {
    title: '[晕]',
    src: require('@/assets/image/emoji_png/emoji_yun.png')
  },
  {
    title: '[鄙视]',
    src: require('@/assets/image/emoji_png/emoji_bishi.png')
  },
  {
    title: '[哼]',
    src: require('@/assets/image/emoji_png/emoji_heng.png')
  },
  {
    title: '[哈欠]',
    src: require('@/assets/image/emoji_png/emoji_dahaqian.png')
  },
  {
    title: '[泪]',
    src: require('@/assets/image/emoji_png/emoji_leimu.png')
  },
  {
    title: '[怒]',
    src: require('@/assets/image/emoji_png/emoji_nu.png')
  },
  {
    title: '[闭嘴]',
    src: require('@/assets/image/emoji_png/emoji_bizui.png')
  },
  {
    title: '[疑问]',
    src: require('@/assets/image/emoji_png/emoji_ningwen.png')
  },
  {
    title: '[白眼]',
    src: require('@/assets/image/emoji_png/emoji_landelini.png')
  },
  {
    title: '[吐]',
    src: require('@/assets/image/emoji_png/emoji_tu.png')
  },
  {
    title: '[黑线]',
    src: require('@/assets/image/emoji_png/emoji_heixian.png')
  },
  {
    title: '[委屈]',
    src: require('@/assets/image/emoji_png/emoji_weiqu.png')
  },
  {
    title: '[笑而不语]',
    src: require('@/assets/image/emoji_png/emoji_xiaoerbuyu.png')
  },
  {
    title: '[阴险]',
    src: require('@/assets/image/emoji_png/emoji_yinxian.png')
  },
  {
    title: '[嘘]',
    src: require('@/assets/image/emoji_png/emoji_xu.png')
  },
  {
    title: '[嘻嘻]',
    src: require('@/assets/image/emoji_png/emoji_xixi.png')
  },
  {
    title: '[爱你]',
    src: require('@/assets/image/emoji_png/emoji_aini.png')
  },
  {
    title: '[吃惊]',
    src: require('@/assets/image/emoji_png/emoji_chijing.png')
  },
  {
    title: '[污]',
    src: require('@/assets/image/emoji_png/emoji_wu.png')
  },
  {
    title: '[鼓掌]',
    src: require('@/assets/image/emoji_png/emoji_guzhang.png')
  },
  {
    title: '[憧憬]',
    src: require('@/assets/image/emoji_png/emoji_chongjing.png')
  },
  {
    title: '[酷]',
    src: require('@/assets/image/emoji_png/emoji_ku.png')
  },
  {
    title: '[失望]',
    src: require('@/assets/image/emoji_png/emoji_shiwang.png')
  },
  {
    title: '[good]',
    src: require('@/assets/image/emoji_png/emoji_good.png')
  },
  {
    title: '[弱]',
    src: require('@/assets/image/emoji_png/emoji_ruo.png')
  },
  {
    title: '[耶]',
    src: require('@/assets/image/emoji_png/emoji_ye.png')
  },
  {
    title: '[来]',
    src: require('@/assets/image/emoji_png/emoji_guolai.png')
  },
  {
    title: '[握手]',
    src: require('@/assets/image/emoji_png/emoji_woshou.png')
  },
  {
    title: '[加油]',
    src: require('@/assets/image/emoji_png/emoji_jiayou.png')
  },
  {
    title: '[haha]',
    src: require('@/assets/image/emoji_png/emoji_hahashoushi.png')
  },
  {
    title: '[拳头]',
    src: require('@/assets/image/emoji_png/emoji_quantou.png')
  },
  {
    title: '[赞]',
    src: require('@/assets/image/emoji_png/emoji_zan.png')
  },
  {
    title: '[ok]',
    src: require('@/assets/image/emoji_png/emoji_ok.png')
  },
  {
    title: '[NO]',
    src: require('@/assets/image/emoji_png/emoji_no.png')
  },
  {
    title: '[作揖]',
    src: require('@/assets/image/emoji_png/emoji_zuoyi.png')
  },
  {
    title: '[心]',
    src: require('@/assets/image/emoji_png/emoji_xin.png')
  },
  {
    title: '[伤心]',
    src: require('@/assets/image/emoji_png/emoji_xinsui.png')
  }
];
