import Vue from 'vue';
import { mapState } from 'vuex';
import ElementUI from 'element-ui';
import VueCookies from 'vue-cookies';
import App from './App.vue';

import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/base.scss';
import '@/assets/css/theme_color.scss';
import store from './store';
import router from './router';
import { mouseMove, mouseClick, visbilityEvent } from './util/index.js';

try {
  Vue.config.devtools = /127\.0\.0\.1|localhost|test\.www\.tantao\.com/gi.test(location.hostname);

  if (Vue.config.devtools) {
    console.log(
      `%c vue-devtools %c ${Vue.config.devtools} %c`,
      'background: #35495e; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;',
      'background: #41b883; padding: 1px; border-radius: 0 3px 3px 0; color: #fff;',
      'background: transparent;'
    );
  }
} catch (error) {
  console.log('vue devtools', error);
}

Vue.use(ElementUI);
Vue.use(VueCookies);

// 设置title
import VueWechatTitle from 'vue-wechat-title';
Vue.use(VueWechatTitle);

Vue.prototype.msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: 'success' });
};
Vue.prototype.msgError = function (msg) {
  this.$message({ showClose: true, message: msg, type: 'error' });
};

new Vue({
  render: (h) => h(App),
  store,
  router,
  computed: {
    ...mapState(['pageVisbility', 'loginStatus', 'activeTime', 'token', 'userInfo', 'notificationNum'])
  },
  data() {
    return {
      icon: '', // 当前页 icon
      title: '', // 当前页 title
      isFlicker: false, // 是否在闪烁
      flickerIconOrTitleNum: 0,
      flickerIconOrTitleTimer: null,
      requestTime: 1000 * 60 * 10, // 10 分钟
      leaveTime: 1000 * 60 * 60 * 2 // 2 小时
    };
  },
  watch: {
    pageVisbility: {
      handler(curVal) {
        // 标签被点击
        if (curVal) {
          // 处于闪烁状态
          if (this.isFlicker) {
            this.clearIntervalFlicker();
          }

          // 通知其他页面
          localStorage.removeItem('TT_AT');
          localStorage.setItem('TT_AT', 1);
        }
      }
    },
    token: {
      handler(curVal) {
        this.$store.commit('SET_LOGINSTATUS', Boolean(curVal) && Boolean(this.userInfo));

        if (curVal) {
          this.$store.dispatch('getNotificationNum');
        }
      },
      immediate: true
    }
  },
  methods: {
    // 闪烁 icon 和 title
    flickerIconOrTitle() {
      let _this = this;

      _this.icon = document.querySelector('[rel="icon"]').href;
      _this.title = document.title;

      _this.flickerIconOrTitleTimer = setInterval(() => {
        document.querySelector('[rel="icon"]').href = '/favicon_transparency.ico';

        if (_this.flickerIconOrTitleNum === 0) {
          document.title = `${_this.notificationNum.sum}条新消息`;
          _this.flickerIconOrTitleNum = 1;
        } else {
          document.title = _this.title;
          _this.flickerIconOrTitleNum = 0;
        }

        _this.$nextTick(() => {});

        setTimeout(() => {
          document.querySelector('[rel="icon"]').href = _this.icon;

          _this.$nextTick(() => {
            document.title;
            document.querySelector('[rel="icon"]').href;
          });
        }, 100);
      }, 2000);

      _this.isFlicker = true;
    },
    // 清除闪烁
    clearIntervalFlicker() {
      let _this = this;

      clearInterval(_this.flickerIconOrTitleTimer);

      document.querySelector('[rel="icon"]').href = _this.icon;
      document.title = _this.title + '　　　　　　　　　　　　　　　　　　　　1';
      _this.flickerIconOrTitleNum = 0;

      // 强制更新
      _this.$nextTick(() => {
        document.title = _this.title + '　　　　　　　　　　　　　　　　　　　　2';
        document.querySelector('[rel="icon"]').href = _this.icon;

        _this.$nextTick(() => {});
      });

      // 延时更新；某些浏览器更新 title 会有异常，使用延时更新解决
      setTimeout(() => {
        document.title = _this.title + '　　　　　　　　　　　　　　　　　　　　3';
        document.querySelector('[rel="icon"]').href = _this.icon;
      }, 1000);

      // 延时更新；某些浏览器更新 title 会有异常，使用延时更新解决
      setTimeout(() => {
        document.title = _this.title;
        document.querySelector('[rel="icon"]').href = _this.icon;
      }, 2000);

      _this.isFlicker = false;
    },
    // storage 事件通知
    localStorageEvent() {
      let _this = this;

      window.onbeforeunload = function () {
        // 只有当前页处于显示状态下关闭才会更新数据
        if (_this.pageVisbility) {
          localStorage.setItem('TT_ISSHOW', 0);
        }
      };

      window.addEventListener('storage', function (event) {
        if (event.key === 'TT_MESSAGE') {
          let messageData = JSON.parse(event.newValue) || {};
          let isShow = Number(localStorage.getItem('TT_ISSHOW'));

          if (typeof messageData.like !== 'undefined' && typeof messageData.reply !== 'undefined') {
            // 更新最新数据
            let like = Number(messageData.like);
            let reply = Number(messageData.reply);
            let sum = like + reply;
            _this.$store.commit('SET_NOTIFICATIONNUM', { like, reply, sum });
            _this.$store.commit('SET_ACTIVETIME', Number(messageData.activeTime));

            // 开启闪烁
            if (messageData.init !== 1 && !_this.isFlicker && _this.notificationNum.sum > 0 && isShow === 0) {
              _this.flickerIconOrTitle();
            }
          }
        } else if (event.key === 'TT_AT') {
          let active = Number(localStorage.getItem('TT_AT'));

          if (_this.isFlicker && active === 1) {
            _this.clearIntervalFlicker();
          }
        }
      });
    },
    // 计时请求消息数量
    loopCheck() {
      let _this = this;

      // 1. 判断用户登录状态，如果用户未登录则跳过
      // TODO: 应清除掉计时器
      if (!_this.loginStatus) {
        return;
      }

      let currentTime = new Date().getTime(); // 当前时间
      let messageData = JSON.parse(localStorage.getItem('TT_MESSAGE')) || {}; // 获取缓存数据
      let activeTime = _this.activeTime > messageData.activeTime ? Number(_this.activeTime) : Number(messageData.activeTime);
      _this.$store.commit('SET_ACTIVETIME', activeTime);

      // 更新最新的活动时间
      if (activeTime > messageData.activeTime) {
        messageData.activeTime = activeTime;
        localStorage.setItem('TT_MESSAGE', JSON.stringify(messageData));
      }

      // 2. 判断上一次活动时间是否超过两小时，如果大于 2 小时，则结束
      if (currentTime - activeTime > _this.leaveTime) {
        return;
      }

      // 3. 进一步判断
      // 保留 5 秒余量
      if (currentTime - Number(messageData.updateTime) >= _this.requestTime - 5000) {
        // 如果距离上一次更新数据间隔大于等于 10 分钟，则重新请求最新数据
        _this.$store.dispatch('getNotificationNum').then(() => {
          let isShow = Number(localStorage.getItem('TT_ISSHOW'));
          /**
           * 有可能是新打开页面更新了数据，但是不需要闪烁
           * 等待所有页面中满足 10 分钟请求了接口更新数据的通知
           */
          if (!_this.isFlicker && _this.notificationNum.sum > 0 && isShow === 0) {
            _this.flickerIconOrTitle();
          }
        });
      } else {
        // 如果距离上一次更新数据间隔小于 10 分钟，则更新数据
        let like = Number(messageData.like);
        let reply = Number(messageData.reply);
        let sum = like + reply;
        _this.$store.commit('SET_NOTIFICATIONNUM', { like, reply, sum });
      }
    }
  },
  created() {
    let _this = this;

    _this.localStorageEvent();

    // 页面显隐事件
    visbilityEvent((value) => {
      // 更新页面显隐状态
      _this.$store.commit('SET_PAGEVISBILITY', value);

      localStorage.setItem('TT_ISSHOW', Number(value));
    });

    // 鼠标移动事件
    mouseMove(() => {
      let currentTime = new Date().getTime();

      // 登录状态 && 2 小时内
      if (_this.loginStatus && currentTime - Number(_this.activeTime) <= _this.leaveTime) {
        // 更新当前页活动时间戳
        _this.$store.commit('SET_ACTIVETIME', currentTime);
      }
    });

    // 鼠标点击事件
    mouseClick(() => {
      let currentTime = new Date().getTime();

      if (_this.isFlicker) {
        _this.clearIntervalFlicker();
      }

      // 通知其他页面
      localStorage.removeItem('TT_AT');
      localStorage.setItem('TT_AT', 1);

      // 更新当前页活动时间戳
      _this.$store.commit('SET_ACTIVETIME', currentTime);

      // 2 小时内点击
      if (currentTime - Number(_this.activeTime) > _this.leaveTime) {
        // 如果距离上一次更新数据间隔大于等于 10 分钟，则重新请求最新数据
        _this.$store.dispatch('getNotificationNum');
      }
    });

    // 计时检测消息数量
    setInterval(_this.loopCheck, _this.requestTime);
  }
}).$mount('#app');
