import Store from '@/store/index.js';
import axios from './axios.js';

/**
 * 获取图片验证码
 */
export function axiosGetImgCode() {
  return axios({
    method: 'get',
    url: '/common/getcaptcha'
  });
}

/**
 * 获取图片验证码
 */
export function getMobileCode(params) {
  return axios({
    method: 'get',
    url: '/common/getsmscode',
    params
  });
}

/**
 * 登录
 */
export function axiosLogin(params, type) {
  params.abnormal_id = Store.state.abnormalReportKey;
  params.qid = 'null';

  let requireObject = {};

  if (type === 'mobile') {
    requireObject.method = 'get';
    requireObject.url = '/account/loginbysms';
    requireObject.params = params;
  } else {
    requireObject.method = 'post';
    requireObject.url = '/account/loginbypassword';
    requireObject.data = params;
  }

  return axios(requireObject);
}

/**
 * 忘记密码
 */
export function forgetPassword(params) {
  return axios({
    method: 'post',
    url: '/account/forgetpassword',
    data: params
  });
}

/**
 * 获取用户头像列表
 */
export function axiosIconList() {
  return axios({
    method: 'get',
    url: '/account/avatars'
  });
}

/**
 * 修改用户信息
 */
export function editUserInfo(params) {
  return axios({
    method: 'post',
    url: '/user/updateprofile',
    data: params
  });
}

/**
 * 修改密码
 */
export function changePassword(params) {
  return axios({
    method: 'post',
    url: '/user/updatepsw',
    data: params
  });
}

/**
 * 退出登录
 */
export function axiosLogout() {
  return axios({
    method: 'post',
    url: '/account/logout'
  });
}

/**
 * 当前话题各投票选项总票数
 */
export function talkIdVote(params) {
  return axios({
    method: 'get',
    url: '/v1/vote/option-totals',
    params
  });
}

/**
 * 用户投票
 */
export function axiosVote(params) {
  params.extra_field = `${'null'}\t${'TANTAO'}`;

  return axios({
    method: 'post',
    url: '/v1/vote/add',
    data: params
  });
}

/**
 * 获取用户所投的选项
 */
export function getUserVoteOption(params) {
  return axios({
    method: 'get',
    url: '/v1/vote/get-user-vote-option',
    params
  });
}

/**
 * 添加评论
 */
export function axiosAddComment(params) {
  params.extra_field = `${'null'}\t${'TANTAO'}`;

  return axios({
    method: 'post',
    url: '/v1/comment/add',
    data: params
  });
}

/**
 * 点赞/取消点赞评论
 */
export function axiosLike(params) {
  return axios({
    method: 'post',
    url: '/comment/like',
    data: params
  });
}

/**
 * 举报接口
 */
export function axiosReport(params) {
  return axios({
    method: 'post',
    url: '/v1/report/add',
    data: params
  });
}

/**
 * 获取评论列表
 */
export function axiosGetCommentList(params) {
  return axios({
    method: 'get',
    url: '/v1/comment/list',
    params
  });
}

/**
 * 回复列表
 */
export function axiosGetReplyList(params) {
  return axios({
    method: 'get',
    url: '/v1/comment/list-reply',
    params
  });
}

/**
 * 获取未读消息数量
 */
export function axiosNotificationNum() {
  return axios({
    method: 'get',
    url: '/remind/unreadcount'
  });
}

/**
 * 阅读消息
 */
export function readMessage(params) {
  return axios({
    method: 'put',
    url: '/remind/read',
    data: params
  });
}

/**
 * 回复我的列表
 */
export function replyList(params) {
  return axios({
    method: 'get',
    url: '/remind/replytome',
    params
  });
}

/**
 * 点赞我的列表
 */
export function applaudList(params) {
  return axios({
    method: 'get',
    url: '/remind/liketome',
    params
  });
}

/**
 * 获取话题的阅读数
 */
export function getTopicReadNum(params) {
  return axios({
    method: 'post',
    url: '/v1/article-views/get-talk-views',
    data: params
  });
}

/**
 * 获取文章阅读数
 */
export function getArticleReadNum(params) {
  return axios({
    method: 'post',
    url: '/v1/article/statistic',
    data: params
  });
}

/**
 * 话题列表
 */
export function getConcernList(params) {
  return axios({
    method: 'get',
    url: '/app/v1/remind/talk/update',
    params
  });
}

/**
 * 关注话题动态列表
 */
export function getRecentNews(params) {
  return axios({
    method: 'get',
    url: '/v1/talk/follow/list-with-recent-news',
    params
  });
}

/**
 * 获取用户关注的话题列表
 */
export function getUserConcernList(params) {
  return axios({
    method: 'get',
    url: '/v1/talk/follow/list',
    params
  });
}

/**
 * 推荐关注列表
 */
export function recomendConcern() {
  return axios({
    method: 'get',
    url: '/v1/talk/follow/recommend'
  });
}

/**
 * 用户关注 / 取消关注话题
 */
export function userConcern(params) {
  return axios({
    method: 'post',
    url: '/v1/talk/follow',
    data: params
  });
}
