import { jsonp } from 'vue-jsonp';
let Host = 'www.tantao.com';
if (
  /testwww|localhost|127\.0\.0\.1/.test(location.hostname) ||
  process.env.NODE_ENV === 'development'
) {
  Host = 'testwww.tantao.com';
} 
if (/ztpreview\.eastday\.com/.test(location.hostname)) {
  Host = 'ztpreview.eastday.com';
}
  
// 获取话题推荐新闻数据
export function getTopicData(param) {
  const { uk } = param;
  return jsonp(`//${Host}/api/zt/detail/${uk}.json`, {
    callbackName: `ztDetail${uk}`,
  });
}

// 获取文章详情
export function getNewsData(uk) {
  return jsonp(`//mini.eastday.com/ns/api/detail/newsinfo/${uk}.json`, {
    re: '360applet',
    callbackName: `uk${uk}`,
  });
}

// 获取热门推荐话题
export function getHotRecTopicData() {
  return jsonp(`//${Host}/api/zt/detail-extends/recommend.json`, {
    callbackName: 'talk_recommend',
  });
}

// 获取推荐话题新闻
export function getRecommendNewsData(id) {
  return jsonp(`//${Host}/api/zt/news-detail-extends/recommend${id}.json`, {
    callbackName: `recommend${id}`,
  });
}
