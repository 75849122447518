import VueCookies from 'vue-cookies';

/**
 * javascript原生对象的offset
 * @param {*} Node
 * @param {*} offset
 */
export function getOffset(Node, offset) {
  if (!offset) {
    offset = {};
    offset.top = 0;
    offset.left = 0;
  }
  if (!Node) return offset;
  if (Node == document.body) {
    return offset;
  }

  offset.top += Node.offsetTop;
  offset.left += Node.offsetLeft;
  return getOffset(Node.parentNode, offset); //向上累加offset里的值
}

/**
 * javascript原生判断是否存在该类
 * @param {*} obj
 * @param {*} cls
 */
export function hasClass(obj, cls) {
  if (!obj) return;
  let reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
  return obj.className.match(reg);
}

/**
 * javascript原生添加类
 * @param {*} obj
 * @param {*} cls
 */
export function addClass(obj, cls) {
  if (!obj) return;
  let Obj = obj;
  let objClass = obj.className;
  let blank = objClass !== '' ? ' ' : '';
  if (!hasClass(obj, cls)) {
    let added = objClass.replace(/\s$/, '') + blank + cls;
    Obj.className = added;
  }
}

/**
 * javascript原生删除类
 * @param {*} obj
 * @param {*} cls
 */
export function removeClass(obj, cls) {
  if (!obj) return;
  let Obj = obj;
  let reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
  if (hasClass(obj, cls)) {
    Obj.className = obj.className.replace(reg, ' ');
  }
}

/**
 * 原生获取滚动条位置
 * @param {*} el
 */
export function getScrollPosition(el = window) {
  return {
    x: el.pageXOffset !== undefined ? el.pageXOffset : el.scrollLeft,
    y: el.pageYOffset !== undefined ? el.pageYOffset : el.scrollTop
  };
}

/**
 *
 * @param {*} y 目标纵坐标
 * @param {*} duration 时间(毫秒)
 * @param {*} cb 回调函数
 */
export function scrollTo(y, duration, cb) {
  let scrollTop = getScrollPosition().y; /*页面当前滚动距离*/
  let distance = y - scrollTop; /*结果大于0,说明目标在下方,小于0,说明目标在上方*/
  let scrollCount = duration / 10; /*10毫秒滚动一次,计算滚动次数*/
  let everyDistance = distance / scrollCount; /*滚动距离除以滚动次数计算每次滚动距离*/
  for (let index = 1; index <= scrollCount; index++ /*循环设置scrollBy事件,在duration之内,完成滚动效果*/) {
    setTimeout(function () {
      window.scrollBy(0, everyDistance);
    }, 10 * index);
  }
  cb && cb();
}

/**
 * 动态滚动多少距离
 * @param {*} $cnt      滚动容器
 * @param {*} distance  距离
 * @param {*} duration  时间
 * @param {*} cb        回调
 */
export function scrollToDistance($cnt, distance, duration, cb) {
  let scrollCount = duration / 10;
  let everyDistance = distance / scrollCount; /*滚动距离除以滚动次数计算每次滚动距离*/
  for (let index = 1; index <= scrollCount; index++ /*循环设置scrollBy事件,在duration之内,完成滚动效果*/) {
    setTimeout(function () {
      $cnt.scrollBy && $cnt.scrollBy(0, everyDistance);
    }, 10 * index);
  }
  cb && cb();
}

/**
 * 处理日期格式
 * @param {*} n '2021-03-02 20:38:51
 * @return '03-02 20:38'
 */
export function dealDate(n) {
  return n.replace(/-/g, '/');
  // n = n.replace(/-/g, '/');
  // const d = new Date(n);
  // let h = d.getHours();
  // let m = d.getMinutes();
  // let y = d.getFullYear();
  // if (n.indexOf(':') > -1) {
  //   if (m < 10) m = `0${m}`;
  //   if (h < 10) h = `0${h}`;
  //   return `${y}/${d.getMonth() + 1}/${d.getDate()}  ${h}:${m}`;
  // } else {
  //   return `${y}/${d.getMonth() + 1}/${d.getDate()}`;
  // }
}

/**
 * 获取浏览器视口的宽度
 */
export function getWindowWidth() {
  var de = document.documentElement;
  return self.innerWidth || (de && de.offsetWidth) || document.body.offsetWidth;
}

/**
 * 获取浏览器视口的高度
 */
export function getWindowHeight() {
  var de = document.documentElement;
  return self.innerHeight || (de && de.offsetHeight) || document.body.offsetHeight;
}

/**
 * 获取地址的uk
 * @param {*} u
 */
export function getUrlUk(u) {
  if (!u) return null;
  let uk = u.match(/\/([a-zA-Z]{0,1}\d{1,})\.html/);
  if (uk) {
    return uk[1];
  } else {
    return u;
  }
}

/**
 * 处理日期格式
 * @param {*} n
 */
export function detalMinDate(n) {
  if (typeof n == 'string') {
    return n.replace(/:\d{1,}$/g, '');
  } else {
    return n;
  }
}

/**
 * 从 cookie 中获取 web 端 uid
 */
export function getUID() {
  let UID = VueCookies.get('TT_UID');

  if (!UID) {
    UID = `${new Date().getTime()}${String(Math.random().toString(10).substring(2, 6))}`;

    VueCookies.set('TT_UID', UID, '1y'); // 保存 1 年
  }
  return UID;
}

/**
 * 从 cookie 中获取 frequently
 * 频繁请求短信验证码时需要先验证图形验证码
 */
export function getFrequently() {
  let frequently = VueCookies.get('TT_frequently');

  return frequently;
}

/**
 * 保存 frequently cookie
 * 频繁请求短信验证码时需要先验证图形验证码
 */
export function setFrequently() {
  let date = Math.floor((new Date().setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000 - new Date().getTime()) / 1000);

  VueCookies.set('TT_frequently', 1, date);
}

/**
 * 从 cookie 中获取 token
 */
export function getToken() {
  let token = VueCookies.get('TT_TK');

  return token;
}

/**
 * 保存 token cookie
 */
export function setToken(param) {
  if (param.monthLogin) {
    VueCookies.set('TT_TK', param.data, '30d');
  } else {
    VueCookies.set('TT_TK', param.data, 0);
  }
}

/**
 * 从 cookie 中获取 token userInfo
 */
export function getUserInfo() {
  let userInfo = VueCookies.get('TT_UI');

  return userInfo;
}

/**
 * 保存 userInfo
 */
export function setUserInfo(param) {
  if (param.monthLogin) {
    VueCookies.set('TT_UI', param.data, '30d');
  } else {
    VueCookies.set('TT_UI', param.data, 0);
  }
}

/**
 * 清除 用户信息 token
 */
export function removeUserInfoToken() {
  VueCookies.remove('TT_TK');
  VueCookies.remove('TT_UI');
}

export function loadXMLString(str) {
  var div = document.createElement('div');
  if (typeof str == 'string') div.innerHTML = str;
  return div.childNodes;
}

/**
 * 获取ie的版本
 */
export function isIEversion() {
  const userAgent = window.navigator.userAgent; //取得浏览器的userAgent字符串
  const isEdge = userAgent.indexOf('Edge') > -1 && !isIE; //判断是否IE的Edge浏览器
  const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;
  const isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1;
  if (isIE || isIE11 || isEdge) {
    return true;
  } else {
    return false;
  }
}
/**
 * 支持webp
 */
export function supportWebp() {
  var flag;
  try {
    flag = document.createElement('canvas').toDataURL('image/webp', 0.5).indexOf('data:image/webp') === 0;
  } catch (err) {
    flag = false;
  }
  return flag;
}

/**
 * 是否是高分辨
 */
export function isHresolution() {
  const W = window.screen.width;
  if (W > 1920) {
    return true;
  }
  return false;
}

/**
 * 鼠标移动事件
 */
export function mouseMove(callback) {
  document.addEventListener('mousemove', () => {
    callback();
  });
}

/**
 * 鼠标点击事件
 */
export function mouseClick(callback) {
  document.addEventListener('click', () => {
    callback();
  });
}

/**
 * 浏览器标签显隐事件
 * @param fn 回调函数
 */
export function visbilityEvent(fn) {
  let state = (function getVisibilityState() {
    let prefixes = ['webkit', 'moz', 'ms', 'o'];
    if ('visibilityState' in document) return 'visibilityState';
    for (let i = 0; i < prefixes.length; i++) {
      if (prefixes[i] + 'VisibilityState' in document) return prefixes[i] + 'VisibilityState';
    }
    return null;
  })();
  let visProp = (function getHiddenProp() {
    let prefixes = ['webkit', 'moz', 'ms', 'o'];
    // 如果“hidden”是本地支持的，则返回
    if ('hidden' in document) return 'hidden';
    // 循环遍历所有已知前缀，直到找到一个前缀
    for (let i = 0; i < prefixes.length; i++) {
      if (prefixes[i] + 'Hidden' in document) return prefixes[i] + 'Hidden';
    }
    // 否则不支持
    return null;
  })();
  if (visProp) {
    let evtname = visProp.replace(/[H|h]idden/, '') + 'visibilitychange';

    document.addEventListener(evtname, function (e) {
      let isvisible = document[state] === 'visible';
      fn(isvisible, e);
    });

    let isvisible = document[state] === 'visible';
    fn(isvisible);
  }
}
