import Vue from 'vue';
import Vuex from 'vuex';
import ElementUI from 'element-ui';
import { removeProtocolHeader } from '@/util/methods.js';
import { axiosLogin, axiosNotificationNum, axiosLogout } from '@/api/axios.request.js';
import { getToken, setToken, getUserInfo, setUserInfo, removeUserInfoToken } from '@/util/index.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activeTime: new Date().getTime(), // 当前页最后一次活动状态的时间戳
    pageVisbility: false, // 当前页标签是否处于显示状态
    notificationRequire: 0, // 未读消息请求次数
    loginStatus: false, // 用户当前是否登录的状态
    currentNewsId: '', // 当前新闻Id
    currentTopicId: '', // 当前话题Id
    isHoverTopic: false, // 是否hover标题
    isViewDetail: false, // 是否展现详情页
    accountDialog: false, // 账户 Dialog 对话框
    token: getToken() || '', // token 信息
    userInfo: getUserInfo() || '', // 用户信息
    userEdit: false, // 用户中心 头部点击 编辑个人信息 时，导航至 个人资料页面 并处于编辑状态
    mingzhuhaoDialogVisible: false, // 明珠号提示
    topicInfo: {}, // 话题信息
    voteInfo: {}, // 投票信息
    commentNum: 0, // 话题评论数量
    // 未读消息数
    notificationNum: {
      like: 0,
      reply: 0,
      sum: 0
    },
    // 唯一标识
    abnormalReportKey: '',
    // 是否已经获取过来源
    hasUseRefer: false
  },
  mutations: {
    SET_ACTIVETIME(state, value) {
      state.activeTime = value;
    },
    SET_PAGEVISBILITY(state, value) {
      state.pageVisbility = value;
    },
    SET_NOTIFICATIONREQUIRE(state, value) {
      state.notificationRequire = value;
    },
    SET_LOGINSTATUS(state, value) {
      state.loginStatus = value;
    },
    setViewDetail(state, value) {
      state.isViewDetail = value;
    },
    SET_ACCOUNTDIALOG(state, value) {
      state.accountDialog = value;
    },
    SET_TOKEN(state, value) {
      state.token = value;
    },
    SET_USERINFO(state, value) {
      value.avatar = removeProtocolHeader(value.avatar);

      state.userInfo = value;
    },
    SET_USEREDIT(state, value) {
      state.userEdit = value;
    },
    SET_MINGZHUHAODIALOGVISIBLE(state, value) {
      state.mingzhuhaoDialogVisible = value;
    },
    SET_ISHOVERTOPIC(state, value) {
      state.isHoverTopic = value;
    },
    SET_VOTEINFO(state, value) {
      state.voteInfo = value;
    },
    SET_TOPICINFO(state, value) {
      state.topicInfo = value;
    },
    SET_NOTIFICATIONNUM(state, value) {
      state.notificationNum = value;
    },
    SET_CURRENTTOPICID(state, value) {
      state.currentTopicId = value;
    },
    SET_CURRNETNEWSID(state, value) {
      state.currentNewsId = value;
    },
    CLEAR_USERINFO_TOKEN(state) {
      state.token = '';
      state.userInfo = '';

      removeUserInfoToken();
    },
    SET_COMMENTNUM(state, value) {
      state.commentNum = value;
    },
    SET_ABNORMALREPORTKEY(state, value) {
      state.abnormalReportKey = value;
    },
    SET_HASUSEERFER(state, value) {
      state.hasUseRefer = value;
    }
  },
  actions: {
    /**
     * 登录
     */
    login({ commit }, params) {
      return new Promise((resolve, reject) => {
        let type = String(params.ruleFormName).replace('RuleForm', '');

        let axiosParams = {};

        if (params.mobile) {
          axiosParams = {
            mobile: params.mobile,
            smscode: params.mobileCode
          };

          // 是否需要图形验证码
          if (params.imgCode) {
            axiosParams.capid = params.id;
            axiosParams.capvalue = params.imgCode;
          }
        } else {
          axiosParams = {
            username: params.userName,
            password: params.password,
            capid: params.id,
            capvalue: params.imgCode
          };
        }

        axiosLogin(axiosParams, type)
          .then((result) => {
            const { data } = result;

            if (data.code === 200) {
              ElementUI.Message.success('登录成功');

              commit('SET_TOKEN', data.result.token);
              commit('SET_USERINFO', data.result.userinfo);

              setToken({
                monthLogin: params.monthLogin,
                data: data.result.token
              });

              setUserInfo({
                monthLogin: params.monthLogin,
                data: data.result.userinfo
              });

              location.reload();
            } else {
              ElementUI.Message.error(data.message);
            }

            resolve(data || {});
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * 获取未读消息数量
     */
    getNotificationNum({ commit, state }) {
      return new Promise((resolve, reject) => {
        axiosNotificationNum()
          .then((respose) => {
            const { data } = respose;

            let like = Number(data.result.like);
            let reply = Number(data.result.reply);
            let sum = like + reply;

            commit('SET_NOTIFICATIONREQUIRE', state.notificationRequire + 1);
            commit('SET_NOTIFICATIONNUM', { like, reply, sum });

            let messageData = {
              like,
              reply,
              sum,
              updateTime: new Date().getTime(),
              activeTime: state.activeTime
            };

            // 页面打开时候的数据更新
            if (state.notificationRequire <= 1) {
              messageData.init = 1;
            }

            localStorage.setItem('TT_MESSAGE', JSON.stringify(messageData));
          })
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    /**
     * 退出登录
     */
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        axiosLogout()
          .then((result) => {
            const { data } = result;

            if (data.code === 200) {
              commit('CLEAR_USERINFO_TOKEN');

              // 用户中心不刷新页面
              if (!/\/talk\/user/.test(location.pathname)) {
                location.reload();
              }
            } else {
              ElementUI.Message.error(data.message);
            }

            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  getters: {
    loginStatus: (state) => state.loginStatus,
    isViewDetail: (state) => state.isViewDetail,
    accountDialog: (state) => state.accountDialog,
    token: (state) => state.token,
    userInfo: (state) => state.userInfo,
    userEdit: (state) => state.userEdit,
    mingzhuhaoDialogVisible: (state) => state.mingzhuhaoDialogVisible,
    isHoverTopic: (state) => state.isHoverTopic,
    voteInfo: (state) => state.voteInfo,
    topicInfo: (state) => state.topicInfo,
    notificationNum: (state) => state.notificationNum,
    commentNum: (state) => state.commentNum,
    currentTopicId: (state) => state.currentTopicId,
    currentNewsId: (state) => state.currentNewsId,
    abnormalReportKey: (state) => state.abnormalReportKey,
    hasUseRefer: (state) => state.hasUseRefer
  }
});
