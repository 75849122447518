<template>
  <section class="user-layout">
    <el-container>
      <el-header class="user-header" height="217px">
        <section class="user-header-bg">
          <img :src="userBg" alt="" />
        </section>

        <section class="user-header-info">
          <section class="photo">
            <img :src="userInfo.avatar | removeProtocolHeader" alt="" v-show="userInfo.avatar" />
          </section>
          <section class="info">
            <p class="name-wrap">
              <span class="name">{{ userInfo.nickname }}</span>
              <i class="gender" :class="userInfo.gender | formatGenderClass"></i>
            </p>
            <p class="edit-wrap" @click="toEdit"><i></i><span>编辑个人信息</span></p>
          </section>
        </section>

        <section class="user-logout">
          <p class="logout-btn" @click="logoutDialogVisible = true">
            <span class="icon"></span>
            <span class="text">退出登录</span>
          </p>
        </section>
      </el-header>

      <el-container class="user-container">
        <el-aside width="180px">
          <section class="user-aside-nav" :style="{ marginTop: userNavMarginTop }">
            <section class="nav-list-wrap" v-for="item in navLists" :key="item.title">
              <h3 class="nav-list-title">{{ item.title }}</h3>
              <ul class="nav-lists">
                <li class="nav-lists-item" v-for="item2 in item.childrenLists" :key="item2.name" @click="navToPath(item2.url)" :class="routePath === item2.url ? 'active' : ''">
                  <p>
                    <i class="icon" :style="item2.bg"></i>
                    <span class="text">{{ item2.name }}</span>
                    <i class="point" v-if="item2.redPoint"></i>
                  </p>
                </li>
              </ul>
            </section>
          </section>
        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>

    <!-- 退出登录 -->
    <section class="logout-dialog">
      <el-dialog :visible.sync="logoutDialogVisible">
        <p class="text">确定退出登录吗？</p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="logoutDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="logout">确定</el-button>
        </span>
      </el-dialog>
    </section>

    <!-- 明珠号提示 -->
    <section class="mingzhuhao-dialog">
      <el-dialog :visible.sync="mingzhuhaoDialogVisible">
        <p class="text">请至明珠号后台更改个人资料！</p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeMingzhuhaoDialog">取消</el-button>
          <el-button type="primary" @click="openMingzhuhao">去更改</el-button>
        </span>
      </el-dialog>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { formatGenderClass, removeProtocolHeader } from '@/util/methods.js';
import { setUserInfo } from '@/util/index.js';
import { activeLog } from '@/util/log.js';
import VueCookies from 'vue-cookies';

import userBg01 from '../../assets/image/user_bg/01.png';
import userBg02 from '../../assets/image/user_bg/02.png';
import userBg03 from '../../assets/image/user_bg/03.png';

import userNavBackground01 from '../../assets/image/icon3.png';
import userNavBackground02 from '../../assets/image/title-icon.png';

export default {
  name: 'UserLayout',
  data() {
    return {
      userNavMarginTop: 0,
      // 顶部用户背景
      userBg: '',
      // 退出登录提示框
      logoutDialogVisible: false,
      pagetype: 'user',
      newstype: '',
      fromUrl: document.referrer
    };
  },
  computed: {
    ...mapState(['userInfo', 'accountDialog', 'mingzhuhaoDialogVisible', 'notificationNum', 'abnormalReportKey']),
    // 菜单导航
    navLists() {
      return [
        {
          title: '我的动态',
          childrenLists: [
            {
              name: '评论',
              bg: {
                'background-image': `url("${userNavBackground01}")`,
                'background-position': '0 0'
              },
              url: '/talk/user/comment',
              redPoint: this.notificationNum.reply > 0
            },
            {
              name: '赞',
              bg: {
                'background-image': `url("${userNavBackground01}")`,
                'background-position': '0 -20px'
              },
              url: '/talk/user/applaud',
              redPoint: this.notificationNum.like > 0
            }
          ]
        },
        {
          title: '我的关注',
          childrenLists: [
            {
              name: '话题',
              bg: {
                'background-image': `url("${userNavBackground02}")`,
                'background-position': '0 -100px',
                'background-size': '20px 160px'
              },
              url: '/talk/user/concern',
              redPoint: false
            }
          ]
        },
        {
          title: '账户资料',
          childrenLists: [
            {
              name: '个人资料',
              bg: {
                'background-image': `url("${userNavBackground01}")`,
                'background-position': '0 -40px'
              },
              url: '/talk/user/personal',
              redPoint: false
            },
            // { name: '账号绑定', bg: `url("${userNavBackground01}") 0 -60px`, url: '/talk/user/accountBind', redPoint: false  },
            {
              name: '安全中心',
              bg: {
                'background-image': `url("${userNavBackground01}")`,
                'background-position': '0 -80px'
              },
              url: '/talk/user/security',
              redPoint: false
            }
          ]
        }
      ];
    },
    routePath() {
      return this.$route.path;
    }
  },
  watch: {
    $route: {
      handler() {
        this.logFN();
      },
      immediate: true
    },
    userInfo: {
      handler(curVal) {
        let userBgArr = [userBg01, userBg02, userBg03].sort(() => Math.random() - 0.5);

        if (curVal) {
          curVal.userBg = curVal.userBg || userBgArr[0];

          this.userBg = curVal.userBg;

          setUserInfo({ data: this.userInfo, monthLogin: true });
        }
      },
      deep: true,
      immediate: true
    },
    accountDialog: {
      handler(curVal) {
        if (!curVal) {
          location.reload();
        }
      }
    }
  },
  filters: { formatGenderClass, removeProtocolHeader },
  methods: {
    logFN() {
      let _this = this;

      let pdata = VueCookies.get('TJ_DATA');

      if (pdata) {
        VueCookies.remove('TJ_DATA');

        pdata = pdata.split('|');

        activeLog({
          accid: this.userInfo ? this.userInfo.id : -1,
          btype: pdata[0] || 'null',
          subtype: pdata[1] || 'null',
          pageType: _this.pagetype,
          idx: pdata[2] || 'null',
          newstype: _this.newstype,
          pgnum: pdata[3] || 'null',
          from: _this.fromUrl,
          to: window.location.href,
          fr_url: _this.fromUrl
        });
      } else {
        activeLog({
          accid: this.userInfo ? this.userInfo.id : -1,
          btype: 'null',
          subtype: 'null',
          pageType: _this.pagetype,
          idx: 'null',
          newstype: _this.newstype,
          pgnum: 'null',
          from: 'null',
          to: window.location.href,
          fr_url: 'null'
        });
      }
    },
    navToPath(url) {
      let _this = this;

      _this.$router.push({ path: url });
    },
    toEdit() {
      this.$store.commit('SET_USEREDIT', true);

      this.$router.push({ path: '/talk/user/personal' });
    },
    openMingzhuhao() {
      this.$store.commit('SET_MINGZHUHAODIALOGVISIBLE', false);

      window.open('https://mzmedia.eastday.com', '_blank');
    },
    closeMingzhuhaoDialog() {
      this.$store.commit('SET_MINGZHUHAODIALOGVISIBLE', false);
    },
    logout() {
      let _this = this;

      this.$store.dispatch('logout').then((result) => {
        if (result.code === 200) {
          _this.$store.commit('SET_ACCOUNTDIALOG', true);

          _this.logoutDialogVisible = false;
        }
      });
    },
    scrollEvent() {
      let _this = this;

      let ST = document.documentElement.scrollTop;

      if (ST < 217) {
        _this.userNavMarginTop = `${0 - ST}px`;
      } else {
        _this.userNavMarginTop = '-217px';
      }
    },
    scrollNav() {
      window.addEventListener('scroll', this.scrollEvent);
    }
  },
  created() {
    this.scrollNav();
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollEvent);
  }
};
</script>

<style lang="scss" scoped>
.user-header {
  position: relative;
  padding: 0;

  .user-header-bg {
    position: relative;
    width: 100%;
    height: 160px;
    overflow: hidden;

    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1920px;
      height: 160px;
    }
  }

  .user-header-info {
    position: absolute;
    bottom: 37px;
    left: 50%;
    margin-left: -520px;
    padding: 0 40px;
    width: 1040px;
    font-size: 0;
    box-sizing: border-box;

    .photo {
      float: left;
      width: 86px;
      height: 86px;
      border: 2px solid #fff;
      border-radius: 50%;
      overflow: hidden;
      background-color: #fff;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .info {
      padding-left: 10px;
      height: 90px;
      overflow: hidden;

      p {
        font-size: 0;
      }

      .name-wrap {
        margin-top: 6px;
      }

      .name {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        vertical-align: middle;
      }

      .gender {
        display: inline-block;
        margin-top: 5px;
        margin-left: 5px;
        width: 20px;
        height: 20px;
        vertical-align: top;

        &[class*='men'] {
          background: url('../../assets/image/gender.png') no-repeat;
        }

        &.men {
          background-position: 0 0;
        }

        &.women {
          background-position: 0 -20px;
        }
      }

      .edit-wrap {
        display: inline-block;
        margin-top: 5px;
        height: 20px;
        cursor: pointer;

        i {
          display: inline-block;
          margin-top: 3px;
          margin-right: 5px;
          width: 14px;
          height: 14px;
          background-image: url('../../assets/image/edit.png');
          vertical-align: top;
        }

        span {
          display: inline-block;
          font-size: 14px;
          line-height: 20px;
          color: #1985ff;
          vertical-align: middle;
        }
      }
    }
  }

  .user-logout {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 0;

    .logout-btn {
      cursor: pointer;

      .icon {
        display: inline-block;
        margin-top: 4px;
        margin-right: 10px;
        width: 16px;
        height: 16px;
        vertical-align: top;
        background-image: url('../../assets/image/logout.png');
      }

      .text {
        display: inline-block;
        height: 24px;
        line-height: 24px;
        color: #fff;
        font-size: 14px;
      }
    }
  }
}

.user-container {
  margin: 0 auto;
  width: 1040px;

  .el-aside {
    height: 640px;

    .user-aside-nav {
      position: fixed;
      margin-top: 0;
      padding: 20px;
      width: 180px;
      height: 640px;
      background-color: #fff;
      box-sizing: border-box;

      .nav-list-wrap {
        margin-bottom: 40px;

        .nav-list-title {
          font-size: 16px;
          line-height: 24px;
          color: #333333;
        }

        .nav-lists {
          padding-top: 5px;
        }

        .nav-lists-item {
          padding: 5px 0;
          height: 20px;
          cursor: pointer;

          p {
            position: relative;
            font-size: 0;
          }

          .icon {
            display: inline-block;
            margin-right: 10px;
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }

          .text {
            display: inline-block;
            font-size: 14px;
            line-height: 20px;
            color: #666666;
            vertical-align: middle;
          }

          .point {
            position: absolute;
            top: 50%;
            left: -15px;
            transform: translateY(-50%);
            width: 10px;
            height: 10px;
            background-image: url('../../assets/image/red-dian.png');
          }

          &:hover {
            .text {
              color: #1985ff;
            }
          }

          &.active {
            .text {
              font-weight: bold;
              color: #1985ff;
            }
          }
        }
      }
    }
  }

  .el-main {
    margin-left: 10px;
    padding: 0;
    max-width: 850px;
    background-color: #fff;
  }
}

.logout-dialog {
  /deep/ .el-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    width: auto;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 70px 0 50px;

      .text {
        font-size: 18px;
        text-align: center;
      }
    }

    .el-dialog__footer {
      padding: 0 50px 20px;
      text-align: center;

      .el-button {
        width: 110px;
      }
    }
  }
}

.mingzhuhao-dialog {
  /deep/ .el-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    width: auto;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 70px 0 50px;

      .text {
        font-size: 18px;
        text-align: center;
      }
    }

    .el-dialog__footer {
      padding: 0 50px 20px;
      text-align: center;

      .el-button {
        width: 110px;
      }
    }
  }
}
</style>
