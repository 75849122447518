import { mapState } from 'vuex';
import { getUserVoteOption, axiosVote, talkIdVote, axiosAddComment, axiosGetCommentList, axiosLike, axiosGetReplyList, axiosReport } from '@/api/axios.request.js';
import { setTime, setTimeLast, formatEmojiBr, removeProtocolHeader } from '@/util/methods.js';
import emojiPngList from '@/util/emoji_png.js';

export default {
  name: 'Slider',
  data() {
    return {
      emojiList: emojiPngList, // emoji 表情
      talk_id: 0, // 当前话题 id
      detail_id: 0, // 详情页 uk
      loopTimerID: null,
      innerHeight: 'auto',
      innerWidth: 'auto',
      // PK 样式 hover 效果
      progressHover: '',
      // 用户是否投票
      userIsVote: false,
      // 观点列表
      viewpointTitle: [],
      // 观点投票数量
      viewpointNum: [],
      // 观点投票结束时间
      viewpointEndTime: '无结束时间',
      progressStyle: [
        { width: 0, backgroundColor: 'transparent', transition: 'all 1.5s' },
        { width: 0, backgroundColor: 'transparent', transition: 'all 1.5s' },
        { width: 0, backgroundColor: 'transparent', transition: 'all 1.5s' },
        { width: 0, backgroundColor: 'transparent', transition: 'all 1.5s' }
      ],
      // 投票锁
      voteLock: false,
      // 点击投票按钮的投票信息
      voteObj: {},
      // 投票观点框
      voteDialogVisible: false,
      // 投票评论
      voteTextarea: '',
      // 投票 emoji 显示
      voteEmojiIsShow: false,
      // 评论 tab
      commentTabsActiveName: 'all',
      // 评论 tab 是否可以切换
      commentTabsBeforeFlag: true,
      // 评论 tab 下标
      commentTabsIndex: 0,
      // 评论排列顺序
      commentSortType: 'hot',
      // 评论样式还是回复样式
      isComment: true,
      // loading
      commentLoading: false,
      // 评论样式时记录滚动的高度
      commentScrollTop: 0,
      // 新增评论的缓存数据 在非全部非己方的评论列表时 更新评论列表为 全部
      commentAddTemp: [],
      // 评论列表
      commentObject: {
        detailObject: {
          page_num: 1,
          isEnd: false,
          count: 0,
          list: []
        },
        talkObject: {
          page_num: 1,
          isEnd: false,
          count: 0,
          list: []
        },
        throttle: false, // 节流锁
        page_num: 1,
        isEnd: false,
        scrollFlag: false,
        count: 0,
        list: []
      },
      // 点赞锁
      likeLock: false,
      // 评论 / 回复 锁
      replyLock: false,
      // 评论输入框 focus 状态
      textareaFocus: false,
      // 投票 emoji 显示
      replyEmojiIsShow: false,
      // 回复列表
      replyObject: {
        data: {},
        page_num: 1,
        isEnd: false,
        scrollFlag: false,
        list: []
      },
      // 底部评论输入框 多行文本
      mtext: false,
      // 回复输入框信息
      replyInputObject: {
        index: undefined,
        comment_id: '',
        placeholder: '请发表一些评论吧！',
        content: ''
      },
      // 举报锁
      reportLock: false,
      // 举报的评论 id
      reportCommentId: '',
      // 举报
      reportDialogVisible: false,
      // 举报选项
      reportOptions: [
        { id: 1, title: '广告营销' },
        { id: 2, title: '色情低俗' },
        { id: 3, title: '地域攻击' },
        { id: 4, title: '人身攻击' },
        { id: 5, title: '诈骗骚扰' },
        { id: 6, title: '谣言' },
        { id: 7, title: '反动' },
        { id: 8, title: '其他' }
      ],
      // 举报 active
      reportRadio: 0
    };
  },
  computed: {
    ...mapState(['loginStatus', 'token', 'userInfo', 'voteInfo']),
    // 观点列表 合并 观点列表 + 观点投票数量
    viewpointFormat: function () {
      let _this = this;

      let tempViewpoint = [];

      if (_this.viewpointTitle.length > 0 && _this.viewpointNum.length > 0) {
        _this.viewpointTitle.forEach((element01) => {
          _this.viewpointNum.forEach((element02) => {
            if (element01.id === element02.option_id) {
              tempViewpoint.push({
                topic: element01.topic,
                num: Number(element02.vote_totals),
                option_id: element02.option_id
              });
            }
          });
        });
      }

      return tempViewpoint;
    },
    // 观点列表 总数
    viewpointTotals: function () {
      let _this = this;

      let result = 0;

      result = _this.viewpointFormat.reduce((num, element) => {
        return num + element.num;
      }, 0);

      return result;
    },
    viewpointEndTimeFormat: function () {
      let _this = this;

      let result = setTimeLast(_this.viewpointEndTime);

      return result;
    },
    // 观点列表 序列化之后
    viewpointArr: function () {
      let _this = this;

      let result = [];
      let tempArr = [
        { btnType: 'danger', color: '#ff3358' },
        { btnType: 'warning', color: '#ff9600' },
        { btnType: 'primary', color: '#1985ff' },
        { btnType: 'success', color: '#0dd490' }
      ];

      let sum = _this.viewpointFormat.reduce((num, element) => {
        return num + element.num;
      }, 0);

      _this.viewpointFormat.forEach((element, index) => {
        element.totals = sum;
        element.btnType = tempArr[index].btnType;
        element.color = tempArr[index].color;
        element.proportion = (element.num / sum) * 100;

        result.push(element);
      });

      return result;
    },
    // 评论 tab
    commentTabsArr: function () {
      let initTapsArr = [
        { name: '全部', id: false, activeName: 'all' },
        { name: '红方', id: false, activeName: 'red' },
        { name: '橘方', id: false, activeName: 'orange' },
        { name: '蓝方', id: false, activeName: 'blue' },
        { name: '绿方', id: false, activeName: 'green' }
      ];

      // 红蓝 PK 样式
      if (this.viewpointFormat.length === 2) {
        initTapsArr = [
          { name: '全部', id: false, activeName: 'all' },
          { name: '红方', id: false, activeName: 'red' },
          { name: '蓝方', id: false, activeName: 'blue' }
        ];
      }

      this.viewpointFormat.forEach((element, index) => {
        initTapsArr[index + 1].id = element.option_id;
      });

      return initTapsArr.slice(0, this.viewpointFormat.length + 1);
    }
  },
  filters: { setTime, removeProtocolHeader },
  watch: {
    $route: {
      handler(curVal) {
        this.talk_id = curVal.params.id || 0;
        this.detail_id = curVal.params.uk || 0;
      },
      immediate: true
    },
    // 登录的用户才有的逻辑
    token: {
      handler(curVal) {
        if (curVal) {
          this.getUserVoteInfo();
        }
      },
      immediate: true
    },
    detail_id: {
      handler() {
        if (this.$refs.sliderBox) {
          this.$refs.sliderBox.scrollTop = 0;
        }

        this.isComment = true;

        this.getCommentList();
      },
      immediate: true
    },
    // 观点列表
    voteInfo: {
      handler(curVal) {
        let data = curVal.data || [];

        this.viewpointTitle = data.slice(0, 4);
      },
      deep: true,
      immediate: true
    },
    // 计算观点占比
    viewpointArr: {
      handler(curVal) {
        let _this = this;

        let noVoteWidth = '100%';

        if (curVal.length >= 2) {
          if (curVal.length === 2) {
            noVoteWidth = '50%';

            _this.progressStyle = [
              {
                width: '50%',
                backgroundColor: 'transparent',
                transition: 'all 1.5s'
              },
              {
                width: '50%',
                backgroundColor: 'transparent',
                transition: 'all 1.5s'
              },
              {
                width: '50%',
                backgroundColor: 'transparent',
                transition: 'all 1.5s'
              },
              {
                width: '50%',
                backgroundColor: 'transparent',
                transition: 'all 1.5s'
              }
            ];
          } else {
            noVoteWidth = '100%';

            _this.progressStyle = [
              {
                width: 0,
                backgroundColor: 'transparent',
                transition: 'all 1.5s'
              },
              {
                width: 0,
                backgroundColor: 'transparent',
                transition: 'all 1.5s'
              },
              {
                width: 0,
                backgroundColor: 'transparent',
                transition: 'all 1.5s'
              },
              {
                width: 0,
                backgroundColor: 'transparent',
                transition: 'all 1.5s'
              }
            ];
          }

          setTimeout(() => {
            curVal.forEach((element, index) => {
              _this.progressStyle[index].width = this.userIsVote || this.viewpointEndTimeFormat === '已结束' ? `${element.proportion}%` : noVoteWidth;
              _this.progressStyle[index].backgroundColor = element.color;
              _this.progressStyle[index].transition = 'all 1.5s';
            });
          }, 200);
        }
      },
      immediate: true
    },
    // 评论列表排序顺序
    commentSortType: {
      handler() {
        this.getCommentList();
      }
    },
    // 评论 / 回复
    isComment: {
      handler(curVal) {
        // 如果是评论样式则重置 回复列表 底部评论输入框
        if (curVal) {
          // 当前为评论样式
          if (this.commentScrollTop > 0) {
            setTimeout(() => {
              this.$refs.sliderBox.scrollTop = this.commentScrollTop;
              this.commentScrollTop = 0;
            }, 0);
          }

          this.replyObject = {
            data: {},
            page_num: 1,
            isEnd: false,
            scrollFlag: false,
            list: []
          };

          this.replyInputObject = {
            index: undefined,
            reply_user_name: '',
            comment_id: '',
            placeholder: '请发表一些评论吧！',
            content: ''
          };
        } else {
          // 重置滚动高度
          this.$refs.sliderBox.scrollTop = 0;

          this.replyInputObject = {
            index: undefined,
            reply_user_name: '',
            comment_id: this.replyObject.data.comment_id,
            placeholder: `回复@${this.replyObject.data.nickname}`,
            content: ''
          };
        }
      }
    },
    replyInputObject: {
      handler() {
        let _this = this;

        _this.$nextTick(() => {
          // dom 宽度有变化
          setTimeout(() => {
            let dom = _this.$refs.replyTextarea.$el.firstElementChild;
            let W = dom.offsetWidth;

            let referDom = _this.$refs.textareaHeight;
            referDom.style.width = `${W}px`;
            let referH = referDom.offsetHeight;

            _this.mtext = referH > 22;

            dom.style.overflow = _this.mtext ? 'auto' : 'hidden';
          }, 100);
        });
      },
      deep: true
    }
  },
  methods: {
    formatEmojiBr,
    resizeEvent() {
      let _this = this;

      _this.innerHeight = `${window.innerHeight - document.querySelector('.header').offsetHeight}px`;
      _this.innerWidth = `${document.querySelector('.slider-header').offsetWidth}px`;
    },
    pageResize() {
      let _this = this;

      let loopCount = 0;
      clearInterval(_this.loopTimerID);
      _this.loopTimerID = setInterval(() => {
        loopCount++;
        _this.resizeEvent();

        if (loopCount > 10) {
          clearInterval(_this.loopTimerID);
        }
      }, 100);

      window.addEventListener('resize', _this.resizeEvent);
    },
    // 评论模块滚动事件
    sliderScrollEvent() {
      let scrollDom = this.$refs.sliderBox;
      let childrenArr = scrollDom.children;
      let childrenHeight = 0;

      [].forEach.call(childrenArr, (element) => {
        childrenHeight += element.offsetHeight;
      });

      if (scrollDom.scrollTop + scrollDom.offsetHeight + 100 > childrenHeight && !this.commentObject.scrollFlag && !this.commentObject.isEnd && this.isComment) {
        this.commentObject.scrollFlag = true;
        this.getCommentList({ loadType: 'scroll' });
      } else if (scrollDom.scrollTop + scrollDom.offsetHeight + 100 > childrenHeight && !this.replyObject.scrollFlag && !this.replyObject.isEnd && !this.isComment) {
        this.replyObject.scrollFlag = true;
        this.getReplyList({}, true);
      }
    },
    // PK 样式 hover 事件
    progressEnterEvent(index) {
      this.progressHover = 'hover' + index;
    },
    // PK 样式离开 hover 事件
    progressLeaveEvent() {
      this.progressHover = '';
    },
    // 评论 tab 点击之前
    commentTabsBefore() {
      if (this.commentObject.throttle) {
        this.commentTabsBeforeFlag = false;
        return false;
      } else {
        this.commentTabsBeforeFlag = true;
      }
    },
    // 评论 tab 点击
    commentTabsHandleClick(tab) {
      if (!this.commentTabsBeforeFlag) {
        return;
      }

      this.commentTabsIndex = Number(tab.index);

      // 重置评论列表排序顺序
      this.commentSortType = 'hot';

      // 重新请求评论列表
      this.getCommentList();
    },
    // 2级评论回复 渲染函数
    secondLevelReply(obj) {
      let domArr = [`<span class="reply-item-user">${obj.nickname}：</span>`, obj.reply_user_name ? `回复<span class="reply-and-reply">@${obj.reply_user_name}</span>` : '', this.formatEmojiBr(obj.content)];

      return domArr.join('');
    },
    // 获取用户投票的信息
    getUserVoteInfo() {
      let _this = this;

      getUserVoteOption({
        talk_id: _this.talk_id
      }).then((result) => {
        const { data } = result;

        _this.userIsVote = data.result.voted_option;
      });
    },
    // 切换排列评论排列顺序
    changeCommentSortType() {
      if (this.commentObject.throttle) {
        return;
      }

      this.commentSortType = this.commentSortType === 'newest' ? 'hot' : 'newest';
    },
    // 获取评论列表
    getCommentList(requestInfo = {}) {
      let _this = this;

      let { count, throttle } = _this.commentObject;

      if (throttle) {
        return;
      }

      // 非滚动加载评论列表都需要重置数据对象
      if (requestInfo.loadType !== 'scroll') {
        _this.commentObject = {
          detailObject: {
            page_num: 1,
            isEnd: !_this.detail_id,
            count: 0,
            list: []
          },
          talkObject: {
            page_num: 1,
            isEnd: false,
            count: 0,
            list: []
          },
          throttle: false, // 节流锁
          page_num: 1,
          isEnd: false,
          scrollFlag: false,
          count,
          list: []
        };
      }

      let params = {
        talk_id: _this.talk_id,
        sort_type: _this.commentSortType
      };

      // 如果是新闻详情页
      if (_this.detail_id) {
        params.article_id = _this.detail_id;

        if (!_this.commentObject.detailObject.isEnd) {
          params.is_talk_flag = 0; // 是否文章内显示话题评论。默认为0，1表示是
          params.page_num = _this.commentObject.detailObject.page_num;
        } else {
          params.is_talk_flag = 1; // 是否文章内显示话题评论。默认为0，1表示是
          params.page_num = _this.commentObject.talkObject.page_num;
        }
      } else {
        params.page_num = _this.commentObject.talkObject.page_num;
      }

      // 更新外层对象页数
      _this.commentObject.page_num = params.page_num;

      // 评论 tab 所在的所属方
      if (_this.commentTabsIndex !== 0) {
        params.vote_type = _this.commentTabsArr[_this.commentTabsIndex].id;
      }

      let { page_num, isEnd } = _this.commentObject;

      if (isEnd) {
        return;
      }

      _this.commentObject.throttle = true;

      _this.commentLoading = true;

      axiosGetCommentList(params)
        .then((result) => {
          const { data } = result;

          // 如果是新闻详情页
          if (!_this.commentObject.detailObject.isEnd) {
            // 非全部非己方新增评论
            if (_this.commentAddTemp.length > 0) {
              _this.commentObject.detailObject.list.unshift(_this.commentAddTemp[0]);

              _this.commentAddTemp = [];
            }

            _this.commentObject.detailObject.page_num = ++page_num;
            _this.commentObject.detailObject.isEnd = data.result.isEnd;
            _this.commentObject.detailObject.list.push(...data.result.data);

            DeleteDuplicate([], _this.commentObject.detailObject.list);
          } else {
            // 非全部非己方新增评论
            if (_this.commentAddTemp.length > 0) {
              _this.commentObject.talkObject.list.unshift(_this.commentAddTemp[0]);

              _this.commentAddTemp = [];
            }

            _this.commentObject.talkObject.page_num = ++page_num;
            _this.commentObject.talkObject.isEnd = data.result.isEnd;
            _this.commentObject.talkObject.list.push(...data.result.data);

            DeleteDuplicate([], _this.commentObject.talkObject.list);
          }

          _this.commentObject.isEnd = _this.commentObject.detailObject.isEnd && _this.commentObject.talkObject.isEnd;
          _this.commentObject.scrollFlag = false;
          _this.commentObject.list = _this.commentObject.detailObject.list.concat(_this.commentObject.talkObject.list);

          // 当评论为 全部 tab 时
          if (_this.commentTabsIndex === 0) {
            _this.commentObject.count = data.result.count;
            _this.$store.commit('SET_COMMENTNUM', _this.commentObject.count);
          }

          _this.commentObject.throttle = false;

          _this.commentLoading = false;

          _this.computeCommentDom();

          // 详情页的评论请求完后触发一次滚动事件
          if (_this.detail_id && _this.commentObject.detailObject.isEnd && _this.commentObject.talkObject.page_num <= 1) {
            _this.getCommentList({ loadType: 'scroll' });
          }
        })
        .catch(() => {
          _this.commentObject.throttle = false;

          _this.commentLoading = false;
        });

      // 评论去重
      function DeleteDuplicate(referenceData, sourceData) {
        if (Object.prototype.toString.call(referenceData) === '[object Array]' && Object.prototype.toString.call(sourceData) === '[object Array]') {
          for (let index = 0; index < sourceData.length; index++) {
            if (referenceData.indexOf(String(sourceData[index].comment_id)) > -1) {
              sourceData.splice(index, 1);
              index--;
            } else {
              referenceData.push(String(sourceData[index].comment_id));
            }
          }
        }
      }
    },
    // 获取回复列表
    getReplyList(item = {}, condition) {
      let _this = this;

      if (!condition) {
        return;
      }

      if (item.comment_id) {
        _this.commentScrollTop = _this.$refs.sliderBox.scrollTop;
      }

      if (item.loadType === 'add') {
        _this.replyObject = {
          data: _this.replyObject.data,
          page_num: 1,
          isEnd: false,
          scrollFlag: true,
          list: []
        };

        _this.$nextTick(() => {
          _this.$refs.sliderBox.scrollTop = 0;
        });
      }

      let { data, page_num, isEnd } = _this.replyObject;

      if (isEnd) {
        return;
      }

      _this.commentLoading = true;

      axiosGetReplyList({
        comment_id: item.comment_id || data.comment_id,
        page_num: page_num
      })
        .then((result) => {
          const { data } = result;

          if (data.code === 200) {
            _this.replyObject.page_num++;
            _this.replyObject.isEnd = data.result.isEnd;
            _this.replyObject.scrollFlag = false;
            _this.replyObject.list.push(...data.result.data);

            if (item.comment_id) {
              _this.replyObject.data = item;

              _this.isComment = false;
            }
          } else {
            _this.msgError(data.message);
          }

          _this.commentLoading = false;

          _this.computeReplyDom();
        })
        .catch(() => {
          _this.commentLoading = false;
        });
    },
    // 获取投票数量
    getTalkIdVote() {
      let _this = this;

      talkIdVote({
        talk_id: _this.talk_id
      }).then((respose) => {
        let data = respose.data.result;

        _this.viewpointNum = data.vote_option_totals || [];

        if (data.end_time) {
          _this.viewpointEndTime = data.end_time;
        }
      });
    },
    // 计算评论内容高度
    computeCommentDom() {
      let _this = this;

      _this.$nextTick(() => {
        let commentTextDom = _this.$refs.commentTextDom || [];
        let replyItemTextDom = _this.$refs.replyItemTextDom || [];

        if (commentTextDom.length > 0) {
          [].forEach.call(commentTextDom, (element, index) => {
            if (element.offsetHeight > 96) {
              let temp = _this.commentObject.list[index];
              temp.foldDom = element.offsetHeight > 96;
              temp.foldNum = 1;

              _this.$set(_this.commentObject.list, index, temp);
            }
          });
        }

        if (replyItemTextDom.length > 0) {
          [].forEach.call(replyItemTextDom, (element) => {
            if (element.offsetHeight > 40) {
              let idx1 = Number(element.getAttribute('index'));
              let idx2 = Number(element.getAttribute('index2'));
              let temp = _this.commentObject.list[idx1];

              temp.reply_list.data[idx2].foldDom = element.offsetHeight > 40;
              temp.reply_list.data[idx2].foldNum = 1;

              _this.$set(_this.commentObject.list, idx1, temp);
            }
          });
        }
      });
    },
    // 计算回复内容高度
    computeReplyDom() {
      let _this = this;

      _this.$nextTick(() => {
        let commentTextDom = _this.$refs.commentTextDom;
        let replyItemTextDom = _this.$refs.replyItemTextDom || [];

        if (commentTextDom) {
          _this.replyObject.data.foldDom = commentTextDom.offsetHeight > 96;
          _this.replyObject.data.foldNum = _this.replyObject.data.foldDom ? 1 : 0;
        }

        if (replyItemTextDom.length > 0) {
          [].forEach.call(replyItemTextDom, (element, index) => {
            if (element.offsetHeight > 80) {
              let temp = _this.replyObject.list[index];
              temp.foldDom = element.offsetHeight > 80;
              temp.foldNum = 1;

              _this.$set(_this.replyObject.list, index, temp);
            }
          });
        }
      });
    },
    // 点击 展开 / 收起
    clickCommentDom(type, index, index2, num) {
      if (type === 'comment_1级') {
        // 评论列表 1级

        let temp = this.commentObject.list[index];

        temp.foldNum = num;

        this.$set(this.commentObject.list, index, temp);
      } else if (type === 'comment_2级') {
        // 评论列表 2级

        let temp = this.commentObject.list[index];

        temp.reply_list.data[index2].foldNum = num;

        this.$set(this.commentObject.list, index, temp);
      } else if (type === 'reply_1级') {
        // 回复列表 1级

        let temp = JSON.parse(JSON.stringify(this.replyObject.data));

        temp.foldNum = num;

        this.$set(this.replyObject, 'data', temp);
      } else if (type === 'reply_2级') {
        // 回复列表 2级

        let temp = this.replyObject.list[index2];

        temp.foldNum = num;

        this.$set(this.replyObject.list, index2, temp);
      }
    },
    // 投票按钮
    voteBtn(option_id, viewpoint, proportion) {
      let _this = this;

      if (_this.voteLock) {
        return;
      }

      // 先验证用户是否登录，如果未登录需要先登录才能投票
      if (_this.token) {
        // 如果用户已投票，则不能继续投票
        if (_this.userIsVote) {
          return;
        }

        if (_this.viewpointEndTimeFormat === '已结束') {
          _this.msgError('投票时间已结束！');

          return;
        }

        _this.voteDialogVisible = true;

        _this.voteObj = {
          option_id,
          proportion,
          viewpoint: `我支持“${viewpoint}”观点`,
          placeholder: '快为你的观点发声吧~'
        };

        _this.voteTextarea = _this.voteObj.viewpoint;

        _this.voteLock = true;

        if (_this.viewpointArr.length > 2) {
          _this.viewpointArr.forEach((element, index) => {
            _this.progressStyle[index].width = `0%`;
            _this.progressStyle[index].backgroundColor = element.color;
            _this.progressStyle[index].transition = 'all 0s';
          });
        }

        axiosVote({
          talk_id: _this.talk_id,
          vote_option_id: option_id
        })
          .then((result) => {
            const { data } = result;

            if (data.code === 200) {
              _this.msgSuccess('投票成功！');
            } else {
              _this.msgError(data.message);
            }

            // 获取用户投票信息
            _this.getUserVoteInfo();

            // 更新投票数量
            _this.getTalkIdVote();
            // _this.viewpointNum.forEach((element) => {
            //   if (element.option_id === option_id) {
            //     element.vote_totals++;
            //   }
            // });

            _this.voteLock = false;
          })
          .catch(() => {
            _this.voteLock = false;
          });
      } else {
        _this.$store.commit('SET_ACCOUNTDIALOG', true);
      }
    },
    // 点赞按钮
    likeBtn(comment_id, is_like, index, type) {
      let _this = this;

      if (_this.likeLock) {
        return;
      }

      // 先验证用户是否登录，如果未登录需要先登录才能投票
      if (_this.token) {
        let like_type = Number(is_like) === 1 ? '0' : '1';

        _this.likeLock = true;

        axiosLike({
          comment_id,
          op_type: like_type
        })
          .then((result) => {
            const { data } = result;

            if (data.code === 200) {
              if (type === 'comment-reply') {
                _this.commentObject.list[index].reply_list.data.forEach((element) => {
                  if (element.comment_id === comment_id) {
                    element.is_like = Number(like_type) === 1;
                    element.like_num = data.result.count;
                  }
                });
              } else if (type === 'reply-reply') {
                _this.replyObject.list.forEach((element) => {
                  if (element.comment_id === comment_id) {
                    element.is_like = Number(like_type) === 1;
                    element.like_num = data.result.count;
                  }
                });
              } else {
                _this.commentObject.list.forEach((element) => {
                  if (element.comment_id === comment_id) {
                    element.is_like = Number(like_type) === 1;
                    element.like_num = data.result.count;
                  }
                });
              }
            } else {
              _this.msgError(data.message);
            }

            _this.likeLock = false;
          })
          .catch(() => {
            _this.likeLock = false;
          });
      } else {
        _this.$store.commit('SET_ACCOUNTDIALOG', true);
      }
    },
    // 回复按钮
    replyBtn(comment_id, nickname, index) {
      let _this = this;

      if (!_this.token) {
        _this.$store.commit('SET_ACCOUNTDIALOG', true);

        return;
      }

      _this.replyInputObject.index = index;
      _this.replyInputObject.comment_id = comment_id;
      _this.replyInputObject.reply_user_name = nickname;
      _this.replyInputObject.placeholder = `回复@${nickname}`;
      _this.replyInputObject.content = ``;

      this.$refs.replyTextarea.$el.firstElementChild.focus();
    },
    // 底部回复框 Focus 事件
    replyFocus() {
      this.textareaFocus = true;
    },
    // 底部回复框 Blur 事件
    replyBlur() {
      this.textareaFocus = false;
    },
    // 底部 emoji 点击事件
    replyEmojiClick(emoji) {
      let dom = this.$refs.replyTextarea.$el.firstElementChild;

      if (document.selection) {
        dom.focus();
        let sel = document.selection.createRange();
        sel.text = emoji;
      } else if (typeof dom.selectionStart === 'number' && typeof dom.selectionEnd === 'number') {
        let startPos = dom.selectionStart;
        let endPos = dom.selectionEnd;
        let cursorPos = startPos;
        let tmpStr = dom.value;
        dom.value = tmpStr.substring(0, startPos) + emoji + tmpStr.substring(endPos, tmpStr.length);
        cursorPos += emoji.length;
        dom.selectionStart = dom.selectionEnd = cursorPos;
      } else {
        this.replyInputObject.content += emoji;
      }

      dom.focus();
    },
    // 重置发布
    replyShowReset() {
      let _this = this;

      _this.replyInputObject = {
        index: undefined,
        reply_user_name: '',
        comment_id: '',
        placeholder: '请发表一些评论吧！',
        content: ''
      };
    },
    // 回复 / 发布
    replyRequest() {
      let _this = this;

      try {
        _this.replyInputObject.content = _this.$refs.replyTextarea.$el.firstElementChild.value;
      } catch (error) {
        console.log(error);
      }

      if (!_this.token) {
        if (_this.replyInputObject.content) {
          sessionStorage.setItem(
            'UNLOGGED_REPLY',
            JSON.stringify({
              reply: _this.replyInputObject,
              comment: _this.isComment ? null : _this.replyObject
            })
          );
        }
        _this.$store.commit('SET_ACCOUNTDIALOG', true);

        return;
      } else if (!_this.replyInputObject.content) {
        _this.msgError('请输入回复内容');

        return;
      } else if (_this.replyLock) {
        return;
      }

      let params = {
        talk_id: _this.talk_id,
        content: _this.replyInputObject.content.replace(/&nbsp;/gi, ' ').trim()
      };

      if (!params.content) {
        _this.msgError('评论不能为空');

        return;
      }

      if (_this.replyInputObject.comment_id) {
        params.comment_id = _this.replyInputObject.comment_id;
      }

      if (_this.detail_id) {
        params.article_id = _this.detail_id;
      }

      _this.replyLock = true;

      axiosAddComment(params)
        .then((result) => {
          const { data } = result;

          if (data.code === 200) {
            if (Number(data.result.examineStatus) === 1) {
              _this.msgSuccess('评论发布成功！');
            } else {
              _this.msgSuccess('评论发布成功，正在审核中，可能不会显示在评论列表中！');
            }

            let replyData = {
              user_id: _this.userInfo.id,
              like_num: 0,
              content: data.result.content,
              create_time: new Date(),
              nickname: data.result.nickname,
              avatar: data.result.avatar,
              reply_user_name: _this.replyInputObject.reply_user_name,
              comment_id: data.result.comment_id,
              reply_list: {
                data: [],
                count: 0
              },
              is_like: false
            };

            _this.commentObject.count++;

            _this.$store.commit('SET_COMMENTNUM', _this.commentObject.count);

            if (_this.isComment) {
              // 当前为评论列表

              // 如果是回复别人的，插入到回复的对应数据中
              if (_this.replyInputObject.comment_id && _this.replyInputObject.index !== undefined) {
                _this.commentObject.list[_this.replyInputObject.index].reply_list.data.unshift(replyData);

                _this.commentObject.list[_this.replyInputObject.index].reply_list.count++;
              } else {
                // 如果是发表评论的

                if (_this.commentTabsIndex !== 0 /*  && String(_this.commentTabsArr[_this.commentTabsIndex].id) !== String(_this.userIsVote) */) {
                  _this.commentTabsActiveName = 'all';
                  _this.commentTabsIndex = 0;

                  _this.commentAddTemp.push(replyData);

                  _this.$nextTick(() => {
                    _this.getCommentList();
                  });
                } else if (_this.commentSortType === 'hot') {
                  // 如果是热门排序方式，则添加到第一条
                  _this.commentObject.list.unshift(replyData);

                  // 当前处在详情页的时候
                  if (_this.detail_id) {
                    _this.commentObject.detailObject.list.unshift(replyData);
                  } else {
                    _this.commentObject.talkObject.list.unshift(replyData);
                  }

                  _this.$refs.sliderBox.scrollTop = 0;
                } else {
                  // 如果是最新排序方式，则直接更新列表
                  _this.getCommentList();
                }
              }

              _this.replyInputObject = {
                index: undefined,
                comment_id: '',
                placeholder: '请发表一些评论吧！',
                content: ''
              };

              _this.replyEmojiIsShow = false;

              _this.computeCommentDom();
            } else {
              // 当前是回复列表

              _this.getReplyList({ loadType: 'add' }, true);
              _this.replyInputObject.index = undefined;
              _this.replyInputObject.reply_user_name = '';
              _this.replyInputObject.content = '';

              // 同时更新 tap 列表数据
              _this.commentObject.list.forEach((element) => {
                if (_this.replyObject.data.comment_id === element.comment_id) {
                  element.reply_list.data.unshift(replyData);
                  element.reply_list.count++;
                }
              });

              _this.replyEmojiIsShow = false;

              _this.computeReplyDom();
            }
          } else {
            _this.msgError(data.message);
          }

          _this.replyLock = false;
        })
        .catch(() => {
          _this.replyLock = false;
        });
    },
    // 清空
    voteEmpty() {
      let _this = this;

      _this.voteTextarea = '';
    },
    // 投票 emoji 点击事件
    voteEmojiClick(emoji) {
      let dom = this.$refs.voteTextarea.$el.firstElementChild;

      if (document.selection) {
        dom.focus();
        let sel = document.selection.createRange();
        sel.text = emoji;
      } else if (typeof dom.selectionStart === 'number' && typeof dom.selectionEnd === 'number') {
        let startPos = dom.selectionStart;
        let endPos = dom.selectionEnd;
        let cursorPos = startPos;
        let tmpStr = dom.value;
        dom.value = tmpStr.substring(0, startPos) + emoji + tmpStr.substring(endPos, tmpStr.length);
        cursorPos += emoji.length;
        dom.selectionStart = dom.selectionEnd = cursorPos;
      } else {
        this.voteTextarea += emoji;
      }

      dom.focus();
    },
    // 投票时发表的评论框
    voteHandleClose(done) {
      this.voteTextarea = '';

      this.voteObj = {};

      done();
    },
    // 投票时发表的评论
    commentRequest() {
      let _this = this;

      try {
        _this.voteTextarea = _this.$refs.voteTextarea.$el.firstElementChild.value;
      } catch (error) {
        console.log(error);
      }

      if (_this.replyLock) {
        return;
      } else if (!_this.voteTextarea) {
        _this.voteDialogVisible = false;

        return;
      }

      _this.replyLock = true;

      axiosAddComment({
        talk_id: _this.talk_id,
        content: _this.voteTextarea.replace(/&nbsp;/gi, ' ').trim()
      })
        .then((result) => {
          const { data } = result;

          if (data.code === 200) {
            if (Number(data.result.examineStatus) === 1) {
              _this.msgSuccess('评论发布成功！');
            } else {
              _this.msgSuccess('评论发布成功，正在审核中，可能不会显示在评论列表中！');
            }

            let replyData = {
              user_id: _this.userInfo.id,
              like_num: 0,
              content: data.result.content,
              create_time: new Date(),
              nickname: data.result.nickname,
              avatar: data.result.avatar,
              reply_user_name: _this.replyInputObject.reply_user_name,
              comment_id: data.result.comment_id,
              reply_list: {
                data: [],
                count: 0
              },
              is_like: false
            };

            _this.commentObject.count++;

            _this.$store.commit('SET_COMMENTNUM', _this.commentObject.count);

            // 如果为回复列表
            if (!_this.isComment) {
              _this.isComment = true;
            }

            // 发布评论
            if (_this.commentTabsIndex !== 0 /* && String(_this.commentTabsArr[_this.commentTabsIndex].id) !== String(_this.userIsVote) */) {
              _this.commentTabsActiveName = 'all';
              _this.commentTabsIndex = 0;

              _this.commentAddTemp.push(replyData);

              _this.$nextTick(() => {
                _this.getCommentList();
              });
            } else if (_this.commentSortType === 'hot') {
              // 如果是热门排序方式，则插入到话题评论的第一条
              _this.commentObject.list.splice(_this.commentObject.detailObject.list.length, 0, replyData);

              // 插入到话题的评论中
              _this.commentObject.talkObject.list.unshift(replyData);

              _this.$refs.sliderBox.scrollTop = 0;
            } else {
              // 如果是最新排序方式，则直接更新列表
              _this.getCommentList();
            }

            _this.voteTextarea = '';

            _this.voteDialogVisible = false;

            _this.computeCommentDom();
          } else {
            _this.msgError(data.message);
          }

          _this.replyLock = false;
        })
        .catch(() => {
          _this.replyLock = false;
        });
    },
    // 举报按钮
    reportBtn(comment_id) {
      this.reportDialogVisible = true;
      this.reportCommentId = comment_id;
    },
    reportHandleClose() {
      this.reportDialogVisible = false;
      this.reportCommentId = '';
      this.reportRadio = '';
    },
    // 提交举报
    reportRequest() {
      let _this = this;

      if (_this.reportLock) {
        return;
      } else if (!_this.reportRadio) {
        _this.msgError('请选择举报的原因！');

        return;
      }

      _this.reportLock = true;

      axiosReport({
        comment_id: _this.reportCommentId,
        report_reason: _this.reportRadio
      })
        .then((result) => {
          const { data } = result;

          if (data.code === 200) {
            _this.msgSuccess('举报成功！');

            this.reportHandleClose();
          } else {
            _this.msgError(data.message);
          }

          _this.reportLock = false;
        })
        .catch(() => {
          _this.reportLock = false;
        });
    }
  },
  created() {
    this.talk_id = this.$route.params.id;
    this.getTalkIdVote();

    // 用户登录状态下
    if (this.loginStatus) {
      let tempData = JSON.parse(sessionStorage.getItem('UNLOGGED_REPLY')) || {};

      // 回复列表的数据
      if (tempData.comment) {
        this.replyObject = tempData.comment;
        this.isComment = false;
      }

      setTimeout(() => {
        // 获取登录前的评论内容
        if (tempData.reply) {
          this.replyInputObject = tempData.reply;
        }
      }, 100);
    }

    sessionStorage.removeItem('UNLOGGED_REPLY');
  },
  mounted() {
    this.pageResize();
  },
  destroyed() {
    clearInterval(this.loopTimerID);
    window.removeEventListener('resize', this.resizeEvent);
  }
};
