<template>
  <section class="talk-layout">
    <router-view></router-view>

    <section class="account-wrap">
      <el-dialog :visible.sync="accountDialog" :close-on-click-modal="false" :before-close="accountClose" custom-class="account-box">
        <Login v-if="accountDialog"></Login>
      </el-dialog>
    </section>

    <!-- 账号注册成功后，更改昵称引导。 -->
    <section class="register-success-guide">
      <el-dialog class="RSG-el-dialog" :visible.sync="RSGDialogVisible" :show-close="false" :close-on-click-modal="false">
        <section class="main-header">
          <span class="jump-over" @click="jumpOver">跳过</span>
        </section>
        <section class="main-body">
          <section class="main-body-left">
            <img :src="registerSuccessImg" alt="" />
          </section>
          <section class="main-body-right">
            <p class="tips-text">快给自己取个新昵称吧，方便朋友找到你~</p>
            <section class="nickname"><el-input v-model="nickname" placeholder="请修改昵称" maxlength="8" show-word-limit></el-input></section>
            <section class="btn">
              <el-button type="primary" @click="editNickname">确定</el-button><span class="edit-more" @click="editMore">修改更多个人信息<i></i></span>
            </section>
          </section>
        </section>
        <section class="main-footer"></section>
      </el-dialog>
    </section>
  </section>
</template>

<script>
import Login from '@/views/account/login.vue';
import { mapState } from 'vuex';
import { editUserInfo } from '@/api/axios.request.js';
import { setUserInfo } from '@/util/index.js';
import { exterLog } from '@/util/log.js';

import registerSuccessImg from '@/assets/image/register-success.png';

export default {
  name: 'TalkLayout',
  components: {
    Login
  },
  data() {
    return {
      // 账号注册成功后，更改昵称引导。
      RSGDialogVisible: false, // RSG => register-success-guide
      registerSuccessImg,
      nickname: '',
      // 修改昵称 错误提示
      messageErrorInterval: true
    };
  },
  computed: {
    ...mapState(['userInfo', 'loginStatus', 'accountDialog'])
  },
  watch: {
    loginStatus: {
      handler(curVal) {
        // is_fresh 是否是新注册的用户

        this.RSGDialogVisible = this.userInfo.is_fresh && curVal;

        if (curVal) {
          // 用户中心页面时不展现引导，直接显示编辑状态
          if (this.RSGDialogVisible && /\/talk\/user/.test(this.$route.path)) {
            this.RSGDialogVisible = false;

            this.$store.commit('SET_USEREDIT', true);
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    accountClose() {
      // 登录弹框
      this.$store.commit('SET_ACCOUNTDIALOG', false);
    },
    // 不再是新用户，没有引导
    userNotFresh() {
      let tempUserInfo = this.userInfo;

      tempUserInfo.is_fresh = false;

      this.$store.commit('SET_USERINFO', tempUserInfo);

      setUserInfo({
        data: tempUserInfo,
        monthLogin: true
      });

      this.RSGDialogVisible = false;
    },
    // 跳过
    jumpOver() {
      this.userNotFresh();
    },
    // 修改昵称
    editNickname() {
      let _this = this;

      if (!_this.nickname) {
        if (_this.messageErrorInterval) {
          _this.msgError('请输入昵称');

          _this.messageErrorInterval = false;

          setTimeout(() => {
            _this.messageErrorInterval = true;
          }, 3000);
        }
      } else {
        editUserInfo({ nickname: _this.nickname }).then((response) => {
          const { code, result, message } = response.data;

          if (code === 200) {
            _this.editVisible = false;

            let tempUserInfo = _this.userInfo;

            tempUserInfo.avatar = result.avatar;
            tempUserInfo.nickname = result.nickname;
            tempUserInfo.gender = result.gender;
            tempUserInfo.is_fresh = false;

            _this.$store.commit('SET_USERINFO', tempUserInfo);

            setUserInfo({
              data: tempUserInfo,
              monthLogin: true
            });

            _this.RSGDialogVisible = false;

            _this.msgSuccess('修改昵称成功！');
          } else {
            _this.msgError(message);
          }
        });

        exterLog('ncyd|qd|0|0', '');
      }
    },
    // 修改更多个人信息
    editMore() {
      let pathUrl = '/talk/user/personal';

      let routeUrl = this.$router.resolve({
        path: pathUrl
      });

      exterLog('ncyd|more|0|0', location.host + pathUrl);

      this.userNotFresh();

      window.open(routeUrl.href, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.talk-layout {
  .account-wrap {
    /deep/ .account-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0 !important;
      width: auto;

      .el-tabs__nav-wrap::after {
        display: none;
      }

      .el-dialog__header {
        padding: 0;

        .el-dialog__headerbtn {
          z-index: 9;
        }
      }

      .el-dialog__body {
        padding: 0;
      }

      .el-tabs__item {
        padding-bottom: 10px;
        height: 20px;
        color: #888888;
        font-size: 16px;
        line-height: 20px;
        box-sizing: content-box;
      }

      .el-tabs__item.is-active {
        color: #333333;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
}

/* 账号注册成功后，更改昵称引导。*/
.register-success-guide {
  /deep/ .RSG-el-dialog {
    .el-dialog {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      margin-top: 0 !important;
      width: 520px;
      border-radius: 3px;

      .el-dialog__header {
        display: none;
      }

      .el-dialog__body {
        padding: 0;

        .main-header {
          padding: 15px;
          height: 28px;
          font-size: 0;
          text-align: right;

          .jump-over {
            display: inline-block;
            width: 55px;
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            text-align: center;
            color: #1e88ff;
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 14px;
            cursor: pointer;
          }
        }

        .main-body {
          padding: 0 30px;
          height: 150px;

          .main-body-left {
            float: left;
            width: 200px;
            height: 143px;

            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }

          .main-body-right {
            padding-left: 10px;
            overflow: hidden;

            .tips-text {
              padding-top: 5px;
              width: 100%;
              font-size: 16px;
              font-weight: bold;
              line-height: 26px;
              letter-spacing: 0px;
              color: #333333;
            }

            .nickname {
              padding-top: 5px;
            }

            .btn {
              padding-top: 10px;

              .el-button {
                margin-right: 20px;
                padding: 10px 20px;
              }

              .edit-more {
                display: inline-block;
                color: #999999;
                font-size: 14px;
                vertical-align: bottom;
                cursor: pointer;

                i {
                  display: inline-block;
                  margin-left: 5px;
                  width: 5px;
                  height: 9px;
                  background: url('../../assets/image/right-arrows.png') center center no-repeat;
                }

                &:hover {
                  color: #409eff;

                  i {
                    background: url('../../assets/image/right-arrows-coloured.png') center center no-repeat;
                  }
                }
              }
            }
          }
        }

        .main-footer {
          padding: 20px;
        }
      }
    }
  }
}
</style>
