<template>
  <section class="app">
    <router-view></router-view>
  </section>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style lang="scss" scoped></style>
