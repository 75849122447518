import axios from 'axios';
import Store from '@/store/index.js';
import ElementUI from 'element-ui';
import MD5 from 'js-md5';
import { getUID } from '@/util/index.js';

const axiosParam = {
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
};

if (location.hostname === 'testwww.tantao.com') {
  axiosParam.baseURL = '//test-api.tantao.com';
}

// 请求
const service = axios.create(axiosParam);

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    let ts = new Date().getTime(); // 当前时间戳
    let nonce = String(Math.random()).substring(2, 12); // 随机数
    let visitor_id = getUID(); // uid 游客id，即web端自动生成的uid

    let sign = MD5(`${String(visitor_id).substring(0, 6)}${config.url}~${nonce}${ts}`) + nonce; // 签名
    let token = Store.state.token; // 登录状态

    // 请求头参数
    config.headers['x-web-sign'] = sign;
    if (token) config.headers['x-web-token'] = token;

    config.params = config.params || {};

    // 公共参数
    config.params.ts = ts;
    config.params.visitor_id = visitor_id;

    // 短信验证码 开发、测试环境专用
    if ((process.env.NODE_ENV === 'development' || location.hostname === 'testwww.tantao.com') && config.url === '/common/getsmscode' && !/syscode=test/.test(location.search)) {
      config.params.mock = 1;
    }

    return config;
  },
  function (error) {
    // 对请求错误做些什么
    ElementUI.Message.error('接口请求失败！');

    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response;
  },
  function (error) {
    // 对响应错误做点什么
    // console.log(error.config);

    if (error.response) {
      const { data } = error.response;

      if (data.code === 401) {
        // token 失效 清空用户信息，并刷新页面
        Store.commit('CLEAR_USERINFO_TOKEN');

        if (!service.error401) {
          ElementUI.Message.error('登录信息已失效，请重新登录');

          service.error401 = true;

          setTimeout(() => {
            service.error401 = false;
          }, 5000);
        }
      } else {
        ElementUI.Message.error('服务异常');
      }
    } else {
      if (/timeout/.test(error.message)) {
        ElementUI.Message.error('网络超时');
      } else {
        ElementUI.Message.error('服务异常');
      }
    }

    return Promise.reject(error);
  }
);

export default service;
