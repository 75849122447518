<template>
  <section class="talk-main-layout">
    <div class="header">
      <div class="header-placeholder" id="header-placeholder">
        <div class="header-inner">
          <img class="logo" :src="logo" alt="logo" @click="backHome" />
          <a
            class="tabnav topic"
            :class="{ current: currnet == 'topic' }"
            @click="clickHeaderNav('topic')"
            >今日话题</a
          >
          <a class="tabnav follow" v-if="loginStatus" @click="clickHeaderNav('follow')">关注动态</a>
          <!-- <a class="tabnav buzz" :class="{ current: currnet == 'buzz' }" @click="clickHeaderNav('buzz')">热评</a> -->
        </div>

        <UserBar></UserBar>
      </div>
    </div>

    <div class="main" id="main-content">
      <router-view></router-view>
    </div>

    <!-- <RelateTopic v-if="isViewDetail"></RelateTopic> -->
  </section>
</template>

<script>
import { mapState } from 'vuex';
import VueCookies from 'vue-cookies';
import UserBar from '@/components/userBar/userBar.vue';
// import { isHresolution } from '@/util/index.js';
import logoPath from '../../assets/image/logo-2.png';
// import logoSmPath from '../../assets/image/logo-s.png';
// import RelateTopic from '@/views/talk/relate/relate.vue';
export default {
  name: 'TalkMainLayout',
  components: {
    UserBar,
    // RelateTopic
  },
  data() {
    return {
      currnet: 'topic',
      talkid: '',
      btype: '', // 话题的btype
      logo: logoPath,
    };
  },
  computed: {
    ...mapState(['token', 'userInfo', 'isViewDetail', 'loginStatus']),
  },
  watch: {},
  methods: {
    clickHeaderNav(name) {
      if (name == 'follow') this.$router.push({path: '/talk/concern'})
      // if (name == 'topic') this.$router.push({ path: '/talk/12345678' });
      // else if (name == 'buzz') this.$router.push({ path: '/talk/buzz' });
      // this.currnet = name;
    },
    backHome() {
      if (this.$route.name === 'currentTalk') {
        this.btype = `topic_${this.talkid}`;
      } else if (this.$route.name === 'Talkdetails') {
        this.btype = `detail`;
      } else if (this.$route.name === 'PAGE404') {
        this.btype = `404`;
      }
      VueCookies.set('TJ_DATA', `${this.btype}|logo|0|0`);
      this.$router.push({
        path: '/talk/home',
      });
      scrollTo(0, 200, () => {});
    },
  },
  created() {
    this.talkid = this.$route.params.id;
    // const isH = isHresolution();
    // if (!isH) this.logo = logoSmPath;
  },
};
</script>

<style lang="scss" scoped>
.talk-main-layout {
  .header {
    position: relative;
    width: 100%;
    height: 60px;
    .header-placeholder {
      width: 100%;
      height: 60px;
      background-color: #fff;
      position: fixed;
      z-index: 11;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    }
    .header-inner {
      width: 1480px;
      float: right;
      height: 60px;
      position: relative;
      .logo {
        position: absolute;
        top: 8px;
        width: 120px;
        height: 44px;
        border: 0;
        cursor: pointer;
        margin-left: 15px;
      }
      .tabnav {
        position: absolute;
        top: 20px;
        left: 180px;
        line-height: 22px;
        font-size: 18px;
        color: #333333;
        text-decoration: none;
        cursor: pointer;
        &.topic {
          left: 180px;
        }
        &.follow {
          left: 310px;
        }
        &.buzz {
          left: 290px;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: -18px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: transparent;
        }
        &.current {
          color: #1985ff;
          font-weight: bold;
        }
        &.current:after {
          background-color: #1985ff;
        }
      }
    }
  }

  .main {
    float: right;
    width: 1480px;
  }
}
</style>
