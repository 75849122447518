<template>
  <div class="talk">
    <Slider />
    <div class="talk-content" id="talk-content">
      <Topic />
      <Main>
        <template v-slot:Talkdetails>
          <transition name="fade">
            <router-view
              v-if="$route.name == 'Talkdetails'"
              :key="$route.fullpath"
            ></router-view>
          </transition>
        </template>
      </Main>
    </div>
  </div>
</template>

<script>
import Topic from './topic/topic';
import Main from './main/main';
import Slider from './slider/slider.vue';
export default {
  name: 'TalkIndexLayout',
  components: {
    Topic,
    Main,
    Slider,
  },
};
</script>

<style lang="scss" scoped>
.talk {
  width: 1480px;
  float: right;
  .talk-content {
    position: relative;
  }
}
.fade-enter-active {
  animation-duration: 0.8s;
  animation-name: fadeInDown;
}
.fade-leave-active {
  animation-duration: 0.6s;
  animation-name: fadeOutDown;
}
</style>
