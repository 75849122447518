import { axiosGetImgCode } from '@/api/axios.request.js';
import emojiPngList from '@/util/emoji_png.js';

// 获取图片验证码
export function getImgCode() {
  let _this = this;

  axiosGetImgCode().then((response) => {
    const { data } = response;

    if (data.code === 200) {
      _this.imgCode = data.result;
    }
  });
}

// 序列化性别
export function formatGender(gender) {
  const genderObj = {
    gender0: '保密',
    gender1: '男',
    gender2: '女'
  };

  return genderObj[`gender${gender}`];
}

// 序列化性别 class
export function formatGenderClass(gender) {
  const genderObj = {
    gender0: 'unknown',
    gender1: 'men',
    gender2: 'women'
  };

  return genderObj[`gender${gender}`];
}

// emoji 换行 表情替换
export function formatEmojiBr(str) {
  let result = String(str || '');

  emojiPngList.forEach((element) => {
    let reg = new RegExp('\\[' + element.title.replace(/\[|\]/g, '') + '\\]', 'g');
    let src = element.src;

    result = result.replace(reg, `<img src="${src}" alt="${element.title.replace(/\[|\]/g, '')}">`);
  });

  result = result.replace(/\{br\}/gi, '<br>').replace(/\n/gi, '<br>');

  return result;
}

// 序列化时间 1天前
export function setTime(time) {
  let T1 = new Date().getTime();
  let T2 = String(time).replace(/-/gi, '/');

  let result = '';

  let TempTime = T1 - new Date(T2).getTime();

  let S = Math.floor(TempTime / 1000);
  let M = Math.floor(TempTime / 1000 / 60);
  let H = Math.floor(TempTime / 1000 / 60 / 60);
  let D = Math.floor(TempTime / 1000 / 60 / 60 / 24);
  let Month = Math.floor(TempTime / 1000 / 60 / 60 / 24 / 30);
  let Y = Math.floor(TempTime / 1000 / 60 / 60 / 24 / 30 / 12);

  if (Y > 0) {
    result = `${Y}年前`;
  } else if (Month > 0) {
    result = `${Month}个月前`;
  } else if (D > 0) {
    result = `${D}天前`;
  } else if (H > 0) {
    result = `${H}小时前`;
  } else if (M > 0) {
    result = `${M}分钟前`;
  } else if (S > 0) {
    result = `${S}秒前`;
  } else {
    result = '刚刚';
  }

  return result;
}

// 序列化时间 1天后
export function setTimeLast(time) {
  if (time === '无结束时间') {
    return '无结束时间';
  }

  let T1 = new Date().getTime();
  let T2 = String(time).replace(/-/gi, '/');

  let result = '';

  let TempTime = new Date(T2).getTime() - T1;

  let S = Math.floor(TempTime / 1000);
  let M = Math.floor(TempTime / 1000 / 60);
  let H = Math.floor(TempTime / 1000 / 60 / 60);
  let D = Math.floor(TempTime / 1000 / 60 / 60 / 24);
  let Month = Math.floor(TempTime / 1000 / 60 / 60 / 24 / 30);
  let Y = Math.floor(TempTime / 1000 / 60 / 60 / 24 / 30 / 12);

  if (Y > 0) {
    result = `${Y}年`;
  } else if (Month > 0) {
    result = `${Month}个月`;
  } else if (D > 0) {
    result = `${D}天`;
  } else if (H > 0) {
    result = `${H}小时`;
  } else if (M > 0) {
    result = `${M}分钟`;
  } else if (S > 0) {
    result = `${S}秒`;
  } else {
    result = '已结束';
  }

  return result;
}

// 序列化时间 2019-08-19 11:38:36
export function timestampSerialize(timestamp) {
  let dateNum = 0;
  let dateNumLength = 0;
  let DATE = '';

  if (isNaN(new Date(timestamp).getTime())) {
    if (!isNaN(new Date(Number(timestamp)).getTime())) {
      // 字符串时间戳
      dateNum = new Date(Number(timestamp)).getTime();
    } else {
      return timestamp;
    }
  } else {
    // 字符串日期 或者 数字时间戳
    dateNum = new Date(timestamp).getTime();
  }

  dateNumLength = String(dateNum).length;

  if (dateNumLength < 10) {
    // 小于 10 位数，默认不是时间戳
    return timestamp;
  } else if (dateNumLength < 13) {
    // 补齐时间戳位数
    dateNum = Number(dateNum) * Math.pow(10, 13 - dateNumLength);
  }

  DATE = new Date(dateNum);

  let year = DATE.getFullYear();
  let month = DATE.getMonth() + 1;
  let day = DATE.getDate();
  let hour = DATE.getHours();
  let minute = DATE.getMinutes();
  let second = DATE.getSeconds();

  let dateArr = [year, month, day];
  let timeArr = [hour, minute, second];

  for (let a = 1; a < dateArr.length; a++) {
    dateArr[a] = String(dateArr[a])[1] ? String(dateArr[a]) : '0' + String(dateArr[a]);
  }

  for (let b = 0; b < timeArr.length; b++) {
    timeArr[b] = String(timeArr[b])[1] ? String(timeArr[b]) : '0' + String(timeArr[b]);
  }

  // 2019-08-19
  // let dateStr = dateArr.join('-');

  // 2019-08-19 11:38:36
  var dateStr = dateArr.join('-') + ' ' + timeArr.join(':');

  return dateStr;
}

// 删除网址协议头
export function removeProtocolHeader(url) {
  return String(url).replace(/^http:\/\/|^https:\/\//, '//');
}
