<template>
  <div class="video">
    <video-player
      class="video-player vjs-custom-skin"
      ref="videoPlayer"
      name="videoPlayer"
      id="video"
      :playsinline="true"
      :globalOptions="globalSetting"
      :options="playerOptions"
      @play="onPlayerPlay"
      @pause="onPlayerPause"
      @ended="onPlayerEnded"
      @waiting="onPlayerWaiting"
      @timeupdate="onPlayerTimeupdate"
      @statechanged="playerStateChanged"
      @canplaythrough="onPlayerCanplaythrough"
      @ready="playerReadied"
    ></video-player>
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-hls';
import 'vue-video-player/src/custom-theme.css';
// import commentsList from '@/components/commentsList';
export default {
  name: 'showVideo',
  components: {
    videoPlayer,
  },
  props: {
    videoLink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      reload: false,
      playTime: '',
      current: '',
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4', // 类型 video/mp4
            src: '', // url地址 //flashwslimit1.eastday.com/multicast/zhibo3/playlist.m3u8
          },
        ],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
      globalSetting: { controls: true },
    };
  },
  created() {
    const link = this.videoLink;
    if (/\.m3u8/.test(link)) {
      this.playerOptions.sources[0].type = 'application/x-mpegURL';
    }
    this.playerOptions.sources[0].src = link;
  },
  beforeRouteLeave() {
    this.$refs.videoPlayer.player.pause();
  },
  methods: {
    /**
     * 播放回调
     * @param player
     */
    onPlayerPlay() {},
    /**
     * 暂停回调
     * @param player
     */
    onPlayerPause() {},
    /**
     * 视频播完回调
     * @param player
     */
    onPlayerEnded() {},
    /**
     * DOM元素上的readyState更改导致播放停止
     * @param player
     */
    onPlayerWaiting() {
      // let time = localStorage.getItem('cacheTime');
      // if (player.cache_.currentTime - Number(time) > 0.1) {
      //   this.current = Number(time);
      //   this.playerReadied(player);
      // } else {
      //   this.current = player.cache_.currentTime;
      // }
    },
    /**
     * 已开始播放回调
     * @param player
     */
    onPlayerPlaying() {},
    /**
     * 当播放器在当前播放位置下载数据时触发
     * @param player
     */
    onPlayerLoadeddata() {},
    /**
     * 当前播放位置发生变化时触发。
     * @param player
     */
    onPlayerTimeupdate() {
      // this.playTime = player.cache_.currentTime;
      // let playTime = player.cache_.currentTime;
      // setTimeout(function() {
      //   localStorage.setItem('cacheTime', playTime);
      // }, 500);
      // let time = localStorage.getItem('cacheTime');
      // if (player.cache_.currentTime - Number(time) > 2) {
      //   this.current = Number(time);
      //   this.playerReadied(player);
      // } else {
      //   this.current = player.cache_.currentTime;
      // }
    },
    /**
     * 媒体的readyState为HAVE_FUTURE_DATA或更高
     * @param player
     */
    onPlayerCanplay() {},
    /**
     * 媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
     * @param player
     */
    onPlayerCanplaythrough() {},
    /**
     * 播放状态改变回调
     * @param playerCurrentState 播放状态
     */
    playerStateChanged() {},
    /**
     * 将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。
     * @param player
     */
    playerReadied() {
      // player.play();
      // player.currentTime(this.current);
    },
  },
};
</script>
