import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);
import TalkLayout from '@/components/talkLayout/talkLayout.vue';
import UserLayout from '@/components/userLayout/userLayout.vue';
import TalkMainLayout from '@/components/talkMainLayout/talkMainLayout.vue';
import TalkIndexLayout from '@/views/talk/index.vue';

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/talk/home',
      redirect: '/'
    },
    {
      path: '/',
      alias: '/index.html',
      component: TalkLayout,
      children: [
        {
          path: '',
          name: 'Home',
          meta: { title: '【探讨】精准清爽看新闻，评论投票有态度！', keepAlive: false },
          component: () => import('@/views/home/home.vue')
        }
      ]
    },
    {
      path: '/talk',
      name: 'Talk',
      component: TalkLayout,
      children: [
        {
          path: 'user',
          name: 'User',
          redirect: '/talk/user/comment',
          component: UserLayout,
          meta: { title: '用户中心', keepAlive: false },
          children: [
            {
              path: 'comment',
              name: 'comment',
              meta: { title: '评论', keepAlive: false },
              component: () => import('@/views/user/comment.vue')
            },
            {
              path: 'applaud',
              name: 'applaud',
              meta: { title: '赞', keepAlive: false },
              component: () => import('@/views/user/applaud.vue')
            },
            {
              path: 'concern',
              name: 'concern',
              meta: { title: '关注', keepAlive: false },
              component: () => import('@/views/user/concern.vue')
            },
            {
              path: 'personal',
              name: 'personal',
              meta: { title: '个人资料', keepAlive: false },
              component: () => import('@/views/user/personal.vue')
            },
            {
              path: 'accountBind',
              name: 'accountBind',
              meta: { title: '账号绑定', keepAlive: false },
              component: () => import('@/views/user/accountBind.vue')
            },
            {
              path: 'security',
              name: 'security',
              meta: { title: '安全中心', keepAlive: false },
              component: () => import('@/views/user/security.vue')
            }
          ]
        },
        {
          path: 'about',
          component: () => import('@/views/about/index.vue'),
          meta: { title: '关于我们', keepAlive: false }
        },
        {
          path: 'concern',
          meta: { title: '动态_探讨', keepAlive: false },
          component: () => import('@/views/concern/concern.vue')
        },
        {
          path: 'error',
          meta: { title: '探讨', keepAlive: false },
          component: TalkMainLayout,
          children: [
            {
              path: '404',
              name: 'PAGE404',
              component: () => import('@/views/404/404.vue')
            }
          ]
        },
        {
          path: ':id',
          // name: 'Id',
          component: TalkMainLayout,
          meta: { title: '今日话题_探讨', keepAlive: false },
          children: [
            {
              path: '',
              name: 'Talk',
              component: TalkIndexLayout,
              children: [
                {
                  path: '',
                  name: 'currentTalk',
                  component: () => import('@/views/talk/main/main.vue')
                },
                {
                  path: 'uk/:uk',
                  name: 'Talkdetails',
                  meta: { title: '话题详情_探讨', keepAlive: false },
                  component: () => import('@/views/details/index.vue')
                }
              ]
            }
          ]
        },
        {
          path: '*',
          redirect: '/talk/error/404'
        }
      ]
    }
  ]
});

const checkLoginPath = ['/talk/user', '/concern']; // 需要验证登陆状态的路由列表
const checkLoginPathReg = new RegExp(checkLoginPath.join('|'));

router.beforeEach((to, from, next) => {
  const token = router.app.$options.store.getters.token;
  const userInfo = router.app.$options.store.getters.userInfo;

  if (to.name !== 'Talkdetails' && to.name !== 'currentTalk' && to.meta.title) {
    document.title = to.meta.title;
  }
  if (checkLoginPathReg.test(to.path)) {
    // 需要验证登录的路由
    if (token && userInfo.nickname) {
      next();
    } else {
      router.app.$options.store.commit('CLEAR_USERINFO_TOKEN');
      next({ path: '/talk/home' });
    }
  } else {
    // 不需要验证登录的路由
    next();
  }
});

export default router;
