<template>
  <section class="user-bar">
    <!-- 登录按钮 -->
    <section class="user-login-btn" v-if="!loginStatus">
      <el-button type="primary" plain @click="loginBtn">登录</el-button>
    </section>

    <!-- 用户信息 -->
    <section class="user-popover-wrap" v-else>
      <img class="user-photo" @click="toUser" :src="userInfo.avatar | removeProtocolHeader" alt="" />

      <el-popover placement="bottom-end" trigger="hover" popper-class="user-popover" class="user-popover">
        <section class="user-wrap" slot="reference">
          <span class="user-name">{{ userInfo.nickname }}</span>
          <i class="icon"></i>
        </section>

        <ul class="user-operation">
          <li class="item" @click="toUser"><i class="user"></i>个人中心</li>
          <li class="item" @click="logout"><i class="logout"></i>退出登录</li>
        </ul>
      </el-popover>
    </section>

    <!-- 消息提示 -->
    <section class="notification" v-if="notificationNum.sum > 0 && notificationIsShow" @click="toReadMessage">
      <p class="text"><i class="icon"></i>您有{{ notificationNum.sum }}条消息未读<i class="close" @click.stop="notificationIsShow = false"></i></p>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import VueCookies from 'vue-cookies';
import { removeProtocolHeader } from '@/util/methods.js';

export default {
  name: 'UserBar',
  data() {
    return {
      btype: 'UserBar',
      notificationIsShow: true
    };
  },
  computed: {
    ...mapState(['userInfo', 'notificationNum', 'loginStatus'])
  },
  watch: {
    $route: {
      handler(curVal) {
        if (/\/talk\/home/.test(curVal.path)) {
          this.btype = 'index';
        } else if (/\/talk\/[\d]{2,}/.test(curVal.path)) {
          this.btype = 'detail';
        }
      },
      immediate: true
    },
    loginStatus: {
      handler(curVal) {
        if (curVal) {
          this.notificationIsShow = true;
        } else {
          this.notificationIsShow = false;
        }
      },
      immediate: true
    }
  },
  filters: { removeProtocolHeader },
  methods: {
    loginBtn() {
      this.$store.commit('SET_ACCOUNTDIALOG', true);
    },
    toUser() {
      let routeUrl = this.$router.resolve({
        path: '/talk/user'
      });

      VueCookies.set('TJ_DATA', `${this.btype}|user|0|0`, 0);

      window.open(routeUrl.href, '_blank');
    },
    toReadMessage() {
      this.notificationIsShow = false;

      let routeUrl = this.$router.resolve({
        path: '/talk/user'
      });

      VueCookies.set('TJ_DATA', `${this.btype}|user|1|0`, 0);

      window.open(routeUrl.href, '_blank');
    },
    logout() {
      this.$store.dispatch('logout');
    }
  }
};
</script>

<style lang="scss" scoped>
.user-bar {
  position: absolute;
  top: 10px;
  right: 30px;

  .user-login-btn {
    padding: 4px 0;

    .el-button {
      padding: 0;
      width: 88px;
      height: 32px;
      line-height: 32px;
      border-radius: 20px;
      background-color: transparent;

      &:hover {
        color: #1985ff;
      }
    }
  }

  .user-popover-wrap {
    float: right;
    cursor: pointer;

    .user-photo {
      width: 38px;
      height: 38px;
      vertical-align: top;
      border-radius: 50%;
      border: 1px solid #eee;
    }

    .user-popover {
      float: right;

      .user-wrap {
        font-size: 0;

        .user-name {
          display: inline-block;
          margin-top: 10px;
          margin-left: 10px;
          margin-right: 10px;
          max-width: 160px;
          height: 20px;
          line-height: 20px;
          font-size: 16px;
          vertical-align: top;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .icon {
          display: inline-block;
          margin-top: 17.5px;
          width: 0;
          height: 0;
          vertical-align: top;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #9498a0;
        }
      }
    }
  }

  .notification {
    float: right;
    margin-top: 2px;
    margin-right: 20px;
    text-align: right;
    cursor: pointer;

    .text {
      position: relative;
      display: inline-block;
      padding: 0 12px;
      height: 36px;
      color: #666666;
      font-size: 14px;
      line-height: 36px;
      background-color: #fff;
      border-radius: 3px;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -6px;
        transform: translateY(-50%) rotate(45deg);
        width: 12px;
        height: 12px;
        background-color: #fff;
        box-shadow: 8px -3px 10px 0px rgba(0, 0, 0, 0.2);
      }

      .icon {
        display: inline-block;
        margin-top: 6px;
        margin-right: 10px;
        width: 20px;
        height: 24px;
        vertical-align: top;
        background: url('../../assets/image/notification.png');
      }

      .close {
        display: inline-block;
        margin-top: 8px;
        margin-left: 8px;
        width: 20px;
        height: 20px;
        vertical-align: top;
        background: url('../../assets/image/notification_close.png') center no-repeat;

        &:hover {
          box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
</style>

<style lang="scss">
.el-popover {
  &.user-popover {
    margin-top: 5px;
    padding: 0;
    min-width: 120px;
    border-radius: 8px;

    .user-operation {
      .item {
        padding: 15px;
        cursor: pointer;

        & + .item {
          border-top: 1px solid #f4f4f4;
        }

        i {
          display: inline-block;
          margin-top: 2px;
          margin-right: 8px;
          width: 16px;
          height: 16px;
          vertical-align: top;

          &.user {
            background: url('../../assets/image/operation-user.png') center no-repeat;
          }

          &.logout {
            background: url('../../assets/image/operation-logout.png') center no-repeat;
          }
        }
      }
    }
  }
}
</style>
