<template>
  <div class="talk-topic-cos" ref="talk-topic-cos" id="talk-topic-cos">
    <div class="talk-topic" :class="animateClass" id="talk-topic" ref="talk-topic" @click="closeDetail" @mouseover="topicMouseOver" @mouseleave="topicMouseLeave">
      <div class="talk-stage" id="talk-stage">
        <p class="title">#{{ topicInfo.name }}#</p>
        <p class="focus-talk">
          <span class="num" v-if="viewTotals !== ''">{{ viewTotals || 0 }}
          </span>
          <span :class="followStatus ? 'followed' : 'follow'" @click="followtopic">{{followStatus ? '已关注' : '+关注话题'}}</span>
        </p>
        <p class="discription">
          <span>导语：</span>
          {{ topicInfo.description }}
        </p>
      </div>
    </div>
    <!-- 关注 -->
    <section class="follow-dialog">
      <el-dialog :visible.sync="followDialogVisible">
        <p class="text">{{ followDialogTitle }}</p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="followDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="followconfirm">确定</el-button>
        </span>
      </el-dialog>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { addClass, removeClass } from "@/util/index.js";
import {
  getUserVoteOption,
  getTopicReadNum,
  userConcern
} from "@/api/axios.request";
export default {
  name: "Topic",
  data() {
    return {
      animateClass: "",
      viewTotals: "",
      followStatus: false, // 关注状态
      followDialogVisible: false, // 关注提示框
      followDialogTitle: ""
    };
  },
  computed: {
    ...mapState([
      "isViewDetail",
      "token",
      "topicInfo",
      "currentTopicId",
      // 'commentNum',
      "isHoverTopic"
    ])
  },
  watch: {
    isViewDetail(newValue) {
      if (newValue) {
        this.animateClass = "narrow-topic";
        this.$nextTick(() => {
          const $talkcos = document.getElementById("talk-topic-cos");
          if ($talkcos) addClass($talkcos, "talk-topic-fixed");
        });
      } else this.animateClass = "";
    },
    isHoverTopic(n) {
      if (n) {
        this.topicMouseOver();
      } else {
        this.topicMouseLeave();
      }
    },
    topicInfo(n) {
      if (n) {
        this.$nextTick(() => {
          this.setH();
          const rname = this.$route.name;
          const $tp = this.$refs["talk-topic"];
          if (rname == "currentTalk" && $tp) {
            $tp.style.transition = "transform 0.5s";
          }
        });
      }
    }
  },
  mounted() {
    this.getTopicNum();
  },
  methods: {
    getTopicNum() {
      // 判断是否移动端环境
      if (
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        let uk;
        if (/\/uk\/(\w?\d+)/.test(location.pathname)) {
          uk =
            /\/uk\/(\w?\d+)/.test(location.pathname) &&
            location.pathname.match(/\/uk\/(\w?\d+)/)[1];
          window.location.href = `/mobile/news/${uk}.html`; // 新闻
        } else {
          uk =
            /\/talk\/(\w?\d+)/.test(location.pathname) &&
            location.pathname.match(/\/talk\/(\w?\d+)/)[1];
          window.location.href = `/mobile/talk/${uk}.html`; // 话题
        }
      }
      const id = this.currentTopicId;
      if (this.token) {
        this.getUserVoteInfo();
      }
      getTopicReadNum({
        talk_id_list: [id]
      }).then(response => {
        if (response && response.data && response.data.result) {
          const { result } = response.data;
          this.viewTotals = result[id];
        }
      });
    },
    closeDetail() {
      if (!this.isViewDetail) return;
      const cnt = document.getElementById("talk-details");
      if (cnt) cnt.style.transition = "none";
      this.$store.commit("setViewDetail", false);
      this.$router.push({ path: `/talk/${this.currentTopicId}` });
    },
    topicMouseOver() {
      if (!this.isViewDetail) return;
      addClass(this.$refs["talk-topic"], "xcp");
    },
    topicMouseLeave() {
      if (!this.isViewDetail) return;
      removeClass(this.$refs["talk-topic"], "xcp");
    },
    setH() {
      // const h = this.$refs['talk-topic-cos'].clientHeight;
      // if (h >= 89) {
      //   this.$refs['talk-topic-cos'].style.height = `${h}px`;
      // }
    },
    getUserVoteInfo() {
      getUserVoteOption({
        talk_id: this.currentTopicId
      }).then(result => {
        const { data } = result;
        this.followStatus = data.result.has_followed;
      });
    },
    followtopic() {
      let _this = this;
      if (_this.token) {
        if (!_this.followStatus) {
          this.followDialogTitle = "确定关注该话题吗？";
        } else if (_this.followStatus) {
          this.followDialogTitle = "确认是否取消关注？";
        }
        this.followDialogVisible = true;
      } else {
        _this.$store.commit("SET_ACCOUNTDIALOG", true);
      }
    },
    followconfirm() {
      let _this = this;
      let param = {
        talk_id: _this.currentTopicId,
        op_type: _this.followStatus ? "0" : "1"
      };
      userConcern(param)
        .then(() => {
          if (!_this.followStatus) {
            _this.followStatus = true;
          } else if (_this.followStatus) {
            _this.followStatus = false;
          }
          this.followDialogVisible = false;
        })
        .catch(() => {
          this.followDialogVisible = false;
        });
    }
  }
};
</script>

<style lang="scss">
.talk-topic-fixed {
  .talk-topic {
    position: fixed;
    top: 60px;
    z-index: 3;
    // padding-top: 18px;
    // padding-bottom: 25px;
    &.narrow-topic {
      .talk-stage {
        padding-top: 20px;
        padding-bottom: 35px;
      }
    }
    .talk-stage {
      // padding-top: 18px;
      // padding-bottom: 25px;
      // padding-top: 20px;
      // padding-bottom: 25px;
      transform: rotateX(2deg);
    }
  }
}
.talk-topic-cos {
  margin-top: 20px;
  min-height: 89px;
}
.talk-topic {
  margin-top: 20px;
  width: 940px;
  box-sizing: border-box;
  transition: all 0.5s ease 0s;
  position: relative;
  top: 0;
  text-align: left;
  .talk-stage {
    padding: 20px 20px 10px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  }
  &.narrow-topic {
    &.xcp {
      opacity: 0.9;
      // top: 32px;
      top: 15px;
      z-index: 4;
      -webkit-transform: scaleX(0.97);
      transform: scaleX(0.97);
      .discription,
      .title {
        opacity: 0.7;
      }
      .talk-stage {
        transform: rotateX(0) translateZ(0) !important;
      }
    }
    .discription,
    .title {
      opacity: 0.4;
    }
  }
  .opacity40 {
    opacity: 0.4;
  }
  .title {
    width: 840px;
    line-height: 30px;
    font-size: 24px;
    font-weight: bold;
    transition: opacity 1s ease 0s;
    vertical-align: top;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .discription {
    line-height: 24px;
    font-size: 14px;
    padding-top: 5px;
    color: #777777;
    transition: opacity 1s ease 0s;
    span {
      color: #212121;
      font-weight: bold;
    }
  }
  .focus-talk {
    position: absolute;
    right: 20px;
    top: 15px;
    .num {
      font-size: 14px;
      float: left;
      line-height: 30px;
      // margin-right: 15px;
      padding-left: 25px;
      position: relative;
      color: #1985ff;
      &::before {
        position: absolute;
        top: 7px;
        left: 0;
        width: 20px;
        height: 20px;
        content: "";
        // background: url('../../../assets/image/reply-coloured.png') no-repeat
        //   center center;
        background: url("../../../assets/image/icon2-coloured.png") no-repeat 0 -42px;
      }
    }
    .attention {
      float: left;
      display: block;
      width: 90px;
      height: 30px;
      border: 1px solid #1985ff;
      border-radius: 15px;
      text-align: center;
      line-height: 30px;
      font-size: 14px;
      color: #1985ff;
      cursor: pointer;
    }
    .follow,
    .followed {
      float: left;
      font-size: 12px;
      cursor: pointer;
      height: 30px;
      line-height: 28px;
      width: 90px;
      text-align: center;
      border-radius: 14px;
      box-sizing: border-box;
      display: block;
      margin-left: 18px;
    }
    .follow {
      color: #1985ff;
      border: 1px solid #1985ff;
      background-color: #fff;
      &:hover {
        color: #fff;
        background-color: #1985ff;
      }
    }
    .followed {
      color: #aaaaaa;
      background-color: #fff;
      border: 1px solid #aaaaaa;
      &:hover {
        background-color: #aaaaaa;
        color: #fff;
        border: 1px solid #aaaaaa;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.follow-dialog {
  /deep/ .el-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    width: auto;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 65px 0 40px;
      width: 360px;

      .text {
        font-size: 18px;
        color: #333333;
        text-align: center;
      }
    }

    .el-dialog__footer {
      text-align: center;
    }
  }
}
</style>