import Store from '@/store/index.js';

/**
 * 获取操作系统
 */
export function getOs() {
  let sUserAgent = navigator.userAgent;
  let isWin = navigator.platform === 'Win32' || navigator.platform === 'Windows';
  let isMac = navigator.platform === 'Mac68K' || navigator.platform === 'MacPPC' || navigator.platform === 'Macintosh' || navigator.platform === 'MacIntel';
  let isUnix = navigator.platform === 'X11' && !isWin && !isMac;
  let isLinux = String(navigator.platform).indexOf('Linux') > -1;
  let bIsAndroid = sUserAgent.toLowerCase().match(/android/i) === 'android';
  let bIsCE = sUserAgent.match(/windows ce/i) === 'windows ce';
  let bIsWM = sUserAgent.match(/windows mobile/i) === 'windows mobile';
  if (isMac) return 'Mac';
  if (isUnix) return 'Unix';
  if (isLinux) {
    if (bIsAndroid) {
      return 'Android';
    }
    return 'Linux';
  }
  if (bIsCE || bIsWM) {
    return 'wm';
  }
  if (isWin) {
    const isWin2K = sUserAgent.indexOf('Windows NT 5.0') > -1 || sUserAgent.indexOf('Windows 2000') > -1;
    if (isWin2K) return 'Win2000';
    const isWinXP = sUserAgent.indexOf('Windows NT 5.1') > -1 || sUserAgent.indexOf('Windows XP') > -1;
    if (isWinXP) return 'WinXP';
    const isWin2003 = sUserAgent.indexOf('Windows NT 5.2') > -1 || sUserAgent.indexOf('Windows 2003') > -1;
    if (isWin2003) return 'Win2003';
    const isWinVista = sUserAgent.indexOf('Windows NT 6.0') > -1 || sUserAgent.indexOf('Windows Vista') > -1;
    if (isWinVista) return 'WinVista';
    const isWin7 = sUserAgent.indexOf('Windows NT 6.1') > -1 || sUserAgent.indexOf('Windows 7') > -1;
    if (isWin7) return 'Win7';
    const isWin8 = sUserAgent.indexOf('Windows NT 6.2') > -1 || sUserAgent.indexOf('Windows 8') > -1;
    if (isWin8) return 'Win8';
    const isWin10 = sUserAgent.indexOf('Windows NT 10.0') > -1 || sUserAgent.indexOf('Windows 10') > -1;
    if (isWin10) return 'Win10';
  }
  return sUserAgent;
}
/**
 * 获取浏览器类型
 */
export function getBrowser() {
  let ua = navigator.userAgent.toLocaleLowerCase(),
    browserType = ua,
    browserVersion = '';
  let spider = checkReptile();
  if (spider) {
    browserType = spider;
  } else if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
    browserType = 'IE';
    browserVersion = ua.match(/msie ([\d.]+)/) != null ? ua.match(/msie ([\d.]+)/)[1] : ua.match(/rv:([\d.]+)/)[1];
  } else if (ua.match(/firefox/) != null) {
    browserType = 'Firefox';
  } else if (ua.match(/ubrowser/) != null) {
    browserType = 'UCBrowser';
  } else if (ua.match(/opr\//) != null) {
    browserType = 'OperaBrowser';
  } else if (ua.match(/bidubrowser/) != null) {
    browserType = 'BaiduBrowser';
  } else if (ua.match(/se 2.x metasr/) != null) {
    browserType = 'SogouBrowser';
  } else if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
    browserType = 'QQBrowser';
  } else if (ua.match(/maxthon/) != null) {
    browserType = 'Maxthon';
  } else if (ua.match(/lbbrowser/) != null) {
    browserType = 'liebaoBrowser';
  } else if (ua.match(/2345explorer/) != null) {
    browserType = '2345Browser';
  } else if (ua.match(/edge/) != null) {
    browserType = 'IEedge';
  } else if (ua.match(/chrome/) != null) {
    let _mime = function (option, value) {
      var mimeTypes = navigator.mimeTypes;
      for (var mt in mimeTypes) {
        if (mimeTypes[mt][option] == value) {
          return true;
        }
      }
      return false;
    };
    let is360 = _mime('type', 'application/360softmgrplugin') || _mime('type', 'application/mozilla-npqihooquicklogin');
    if (is360) {
      browserType = '360Browser';
    } else {
      browserType = 'chrome';
    }
  } else if (ua.match(/safari/) != null) {
    browserType = 'Safari';
  }

  function checkReptile() {
    if (ua.match(/baiduspider|\.baidu\.com/)) {
      return 'BaiduSpider';
    } else if (ua.match(/googlebot|\.google\.com/)) {
      return 'googleSpider';
    } else if (ua.match(/360spider|\.360.cn|\.360.com/)) {
      return '360Spider';
    } else if (ua.match(/bingbot|\.bing\.com/)) {
      return 'bingSpider';
    } else if (ua.match(/sosospider|\.soso\.com/)) {
      return 'sosoSpider';
    } else if (ua.match(/(yahoo.*slurp)|(\.yahoo\.com)/)) {
      return 'YahooSpider';
    } else if (ua.match(/sogou web spider|\.sogou\.com/)) {
      return 'sogouSpider';
    }
  }

  return browserType + browserVersion;
}
/**
 * 生成异常上报唯一key
 */
export function abnormalReportKey() {
  var prev = (+new Date() + (((1 + Math.random()) * 0x10000) | 0)).toString(16).substring(1);
  var last = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  let result = 'dftt-' + prev + '-' + last;
  Store.commit('SET_ABNORMALREPORTKEY', result);
  return result;
}
/**
 * 生成用户uid
 */
export function getUserId() {
  return +new Date() + Math.random().toString(10).substring(2, 6);
}
